import {RoundPeriodsDto} from "./round-periods-dto";
import {InformationsByDeliveryDayDto} from "./informations-by-delivery-day-dto";
import {ObjectDTO} from "./object-dto";

export class OrganisationTourneeInfoDto extends ObjectDTO {

  label: string;

  code: string;

  periods: RoundPeriodsDto[] = [];

  informationsByDeliveryDay: InformationsByDeliveryDayDto = new InformationsByDeliveryDayDto();

}
