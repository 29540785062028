import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {combineLatest, Subscription} from 'rxjs';
import {UtilsService} from '../../../core/utils/utils.service';
import {SiteDTO} from '../../../core/dtos/site-dto';
import {Auth2Service} from '../../../core/services/security/auth2.service';
import {ToastService} from '../../../core/services/technique/toast.service';
import {InternationalizationService} from '../../../core/services/i8n/i8n.service';
import {
  FormInfosRound,
  OrganisationsTourneesService
} from '../../../core/services/gestion-production/organisations-tournees.service';
import {OrganisationTourneeInfoDto} from '../../../core/dtos/organisation-tournee-info-dto';
import {RoundPeriodsDto} from '../../../core/dtos/round-periods-dto';
import {Tournee} from '../../../core/dtos/tournee-dto';
import {GraphQLService} from '../../../core/services/technique/graphql.service';
import {PointDeLivraisonService} from '../../../core/services/entities/point-de-livraison.service';
import {PointDeLivraisonDTO} from '../../../core/dtos/point-de-livraison-d-t-o';
import {SearchSupplierWrapper} from '../../../core/suppliers/wrappers/search-supplier-wrapper';
import {SearchSupplier} from '../../../core/suppliers/search-supplier';
import {FiltersContextOrganization} from '../../../core/dtos/filters-context-organization-dto';
import {RepasDTO} from '../../../core/dtos/repas-dto';
import {ContratMenuConviveDTO} from '../../../core/dtos/contratmenuconvive-dto';
import {TourneesService} from '../../../core/services/referentiel/tournees.service';
import {DxSelectBoxComponent, DxTreeViewComponent} from 'devextreme-angular';
import {InfosRoundDto} from '../../../core/dtos/infos-round-dto';
import {FamillesProduitService} from '../../../core/services/entities/familles-produit.service';
import {GenericDatagridService} from '../../../core/services/generics/generic-datagrid.service';
import {ProduitDeclinaisonService} from '../../../core/services/entities/produit-declinaison.service';
import {Workbook} from 'exceljs';
import {exportDataGrid} from 'devextreme/excel_exporter';
import {InformationsByDeliveryDayDto} from "../../../core/dtos/informations-by-delivery-day-dto";

@Component({
  selector: 'yo-organisation-tournee-dialog',
  templateUrl: './organisation-tournee-dialog-edition.component.html',
  styleUrls: ['./organisation-tournee-dialog-edition.component.scss']
})
export class OrganisationTourneeDialogComponent implements OnInit, OnDestroy {

  private subOpenDialog$: Subscription;

  private subFilters$: Subscription;

  private subCheckRows$: Subscription;

  private subSave$: Subscription;

  displayDialog: boolean = false;

  dialogTitle: string;

  idSelectedSite: number;

  sitePlaceholder: string;

  organisationTournees: OrganisationTourneeInfoDto;

  fullScreen: boolean = true;

  selectedRowsPeriods: RoundPeriodsDto[] = [];

  displayPopupPeriods: boolean = false;

  displayPopupAddTournee: boolean = false;

  displayPopupDuplicate: boolean = false;

  displayWarns: boolean = false;

  displayErrors: boolean = false;

  fullScreenWarningsErrors = false;

  displayFullScreenOrganisationTournee = false;

  dateStart: Date = new Date();

  dateEnd: Date = new Date();

  minDate: Date = new Date();

  tournees: Tournee[] = [];

  tourneeSelected: Tournee;

  repas: RepasDTO[] = [];

  repasSelected: RepasDTO[] = [];

  prestations: ContratMenuConviveDTO[] = [];

  allFamillesProduits: any[] = [];

  prestationsSelected: ContratMenuConviveDTO[] = [];

  pointsLivraisons: PointDeLivraisonDTO[] = [];

  plcSelected: PointDeLivraisonDTO;

  orderSelected: number;

  filtersCtx: FiltersContextOrganization;

  currentConsumptionDays: string[] = [];

  labelTourneeToCreate: string;

  poidsMaxTourneeToCreate: number = 0;

  jourLivSelected: string;

  jourConsoSelected: string[] = [];

  treeDataSource: any;

  treeBoxValue: number[];

  codesPlats: string = '';

  @ViewChild(DxTreeViewComponent, { static: false }) treeView: DxTreeViewComponent;

  @ViewChild("inputTournees") inputTournees: DxSelectBoxComponent;

  warnings: any;

  errors: any;

  dayToDuplicateSelected: string;

  daysTargetForDuplication: string[] = [];

  daysWeek: string[] = [
    this.i8nSvc.getLabelFromCode('LUNDI', null),
    this.i8nSvc.getLabelFromCode('MARDI', null),
    this.i8nSvc.getLabelFromCode('MERCREDI', null),
    this.i8nSvc.getLabelFromCode('JEUDI', null),
    this.i8nSvc.getLabelFromCode('VENDREDI', null),
    this.i8nSvc.getLabelFromCode('SAMEDI', null),
    this.i8nSvc.getLabelFromCode('DIMANCHE', null)
  ];

  daysWeekTarget: string[] = [
    this.i8nSvc.getLabelFromCode('LUNDI', null),
    this.i8nSvc.getLabelFromCode('MARDI', null),
    this.i8nSvc.getLabelFromCode('MERCREDI', null),
    this.i8nSvc.getLabelFromCode('JEUDI', null),
    this.i8nSvc.getLabelFromCode('VENDREDI', null),
    this.i8nSvc.getLabelFromCode('SAMEDI', null),
    this.i8nSvc.getLabelFromCode('DIMANCHE', null)
  ];

  selectedRows: any = {
    [this.i8nSvc.getLabelFromCode('LUNDI', null)]: [],
    [this.i8nSvc.getLabelFromCode('MARDI', null)]: [],
    [this.i8nSvc.getLabelFromCode('MERCREDI', null)]: [],
    [this.i8nSvc.getLabelFromCode('JEUDI', null)]: [],
    [this.i8nSvc.getLabelFromCode('VENDREDI', null)]: [],
    [this.i8nSvc.getLabelFromCode('SAMEDI', null)]: [],
    [this.i8nSvc.getLabelFromCode('DIMANCHE', null)]: [],
  };

  allMode: string = 'allPages';

  checkBoxesMode: string = 'always';

  constructor(private orgTourneeSvc: OrganisationsTourneesService,
              private utilsSvc: UtilsService,
              private auth2Svc: Auth2Service,
              private toastSvc: ToastService,
              private graphQlSvc: GraphQLService,
              private tourneeSvc: TourneesService,
              private plcSvc: PointDeLivraisonService,
              private pdSvc: ProduitDeclinaisonService,
              private familleProduitSvc: FamillesProduitService,
              private gds: GenericDatagridService,
              private i8nSvc: InternationalizationService) {
  }

  ngOnInit() {
    this.openDialogSubscription();
    this.initData();
  }

  ngOnDestroy(): void {
    this.utilsSvc.unsubscribe(this.subOpenDialog$);
    this.utilsSvc.unsubscribe(this.subFilters$);
    this.utilsSvc.unsubscribe(this.subSave$);
    this.utilsSvc.unsubscribe(this.subCheckRows$);
    this.clear();
  }

  initData = () => {
    this.dateStart = new Date();
    this.minDate = new Date(this.dateStart.getTime() + (1000 * 60 * 60 * 24));
    this.dateEnd = new Date(this.dateStart.getTime() + (1000 * 60 * 60 * 24));
  }

  toggleFullScreenWarningsErrors = (): void => { this.fullScreenWarningsErrors = !this.fullScreenWarningsErrors };

  loadTournees = (): void => {
    this.graphQlSvc.sendQuery(`
            {
              allTournees(filters: {
                siteIds: [${this.auth2Svc.utilisateur.sites.map(s => s.id)}],
                actif: true
              }) {
                  id,
                  libelle
              }
            }
          `)
      .toPromise()
      .then(response => this.tournees = response.allTournees);
  }

  loadFamilles = (): void => {
    this.graphQlSvc.sendQuery(`
            {
              allFamillesDeProduit(filters: {
                siteIds: [${this.auth2Svc.utilisateur.sites.map(s => s.id)}],
                actif: true,
                fabrique: true
              }) {
                  id,
                  libelle,
                  parent { id, libelle }
              }
            }
          `)
      .toPromise()
      .then(response => {
        this.allFamillesProduits = response.allFamillesDeProduit;
        this.allFamillesProduits = this.allFamillesProduits.map(fp => { if (fp.parent?.id === -1) fp.parent = null; return fp; });
      });
  }

  loadFilters = (): void => {
    this.loadTournees();
    this.loadFamilles();
    const ssw: SearchSupplierWrapper = new SearchSupplierWrapper();
    ssw.filtersMap['sites'] = new SearchSupplier(undefined, this.auth2Svc.utilisateur.sites.map(s => s.id));
    this.subFilters$ = combineLatest([
      this.plcSvc.getPlcWithoutMcPlcList(null, null, ssw),
      this.orgTourneeSvc.fetchFiltersContext(),
      // this.gds.getAll(this.familleProduitSvc.getEntityName(), this.familleProduitSvc.getSort(), true)
    ]).subscribe(res => {
        this.filtersCtx = res[1].one;

        this.pointsLivraisons = [...new Map((res[0].resultList || []).map((item: PointDeLivraisonDTO) => [item["id"], item])).values()] as PointDeLivraisonDTO[];

        this.pointsLivraisons = this.pointsLivraisons.filter(plc => Object.keys(this.filtersCtx.allConsumptionDaysByPlc).includes(`${plc.id}`));

        this.repas = this.filtersCtx.allPrestationRoundContext.map(item => ({ id: item.idRepas, libelle: item.labelRepas } as RepasDTO));
        this.repas = [...new Map((this.repas).map((item: RepasDTO) => [item["id"], item])).values()] as RepasDTO[];

        this.prestations = this.filtersCtx.allPrestationRoundContext.map(item => ({ id: item.prestation.id, libelle: item.prestation.libelle } as ContratMenuConviveDTO));
        this.prestations = [...new Map((this.prestations).map((item: ContratMenuConviveDTO) => [item["id"], item])).values()] as ContratMenuConviveDTO[];

        //this.allFamillesProduits = res[2].resultList.filter((r: FamilleProduitDTO) => r.fabrique === true).map(x => { delete(x.referenceSite); delete(x.selected); delete(x.codeSite); return x; });
    });
  }

  openDialogAddTournee = (): void => {
    if (this.idSelectedSite)
      this.displayPopupAddTournee = true;
  }

  openDialogDuplicateDay = (): void => {
    this.displayPopupDuplicate = true;
  }

  switchFullScreenOrganisationTournee = (): void => {
    this.displayFullScreenOrganisationTournee = !this.displayFullScreenOrganisationTournee;
  }

  deleteItems = (): void => {
    this.daysWeek.forEach(day => {
      this.selectedRows[day].forEach((sr : InfosRoundDto) => {
        this.organisationTournees.informationsByDeliveryDay[day].forEach((item : InfosRoundDto) => {
          if (this.equals(sr, item))
            this.organisationTournees.informationsByDeliveryDay[day] = this.organisationTournees.informationsByDeliveryDay[day].filter(it => !this.equals(it, item));
        });
      });
    });
  }

  deletePeriodes = (): void => {
    this.organisationTournees.periods = this.organisationTournees.periods
      .filter(period => !this.selectedRowsPeriods.find(item => item.start === period.start && item.end === period.end));
  }

  save = (): void => {
    this.subSave$ = this.orgTourneeSvc.save(this.organisationTournees, false)
      .subscribe((res) => {
        this.initializeWarnings(res);
        this.organisationTournees.id = res.one.organizationRound.id;
        this.orgTourneeSvc.announceRefreshList();
      });
  }

  /**
   * Verifie les nouvelles lignes ajoutés par l'utilisateur
   * Récupere les erreurs pour les afficher à l'utilisateur
   * Si aucune erreur présente on peut ajouter les nouvelles lignes
   * @param organisationTourneesBackup
   */
  checkRows = (jourLivSelected: any, info: InfosRoundDto): void => {
    const organisationTourneesToSave = JSON.parse(JSON.stringify(this.organisationTournees));
    organisationTourneesToSave.informationsByDeliveryDay[jourLivSelected].push(info)
    this.subCheckRows$ = this.orgTourneeSvc.save(organisationTourneesToSave, true)
      .subscribe((res) => {
        const anyErrors = this.initializeErrors(res);
        if (!anyErrors) {
          this.organisationTournees.informationsByDeliveryDay[jourLivSelected].push(info)
        }
      });
  }

  displayWarningsTab = (): boolean => {
    return this.displayWarns && (this.warnings?.pointsLivraisonsNotConfigured || this.warnings?.consumptionsDaysNotConfiguredByPointLivraison || this.warnings?.consistencyRepas || this.warnings?.consistencyPrestations);
  }

  displayErrorsTab = (): boolean => {
    return this.displayErrors && (this.errors?.tourneePlcByDeliveryDayWithMultipleOrders || this.errors?.ordersPlcsWithMultipleRounds || this.errors?.tourneeOrderByDeliveryDayWithMultiplePlcs);
  }

  hideWarningsErrors = (): void => {
    this.displayWarns = false;
    this.displayErrors = false;
  }

  private initializeWarnings = (res: any): void => {
    const warnings: any = res.one.consistency.warnings;
    const anyWarnings: boolean = warnings.consistencyPrestations || warnings.consistencyRepas || warnings.consumptionsDaysNotConfiguredByPointLivraison || warnings.pointsLivraisonsNotConfigured;
    this.displayWarns = anyWarnings;
    this.warnings = warnings;
  }

  private initializeErrors = (res: any): boolean => {
    const errors: any = res.one.consistency.errors;
    const anyErrors: boolean = errors.ordersPlcsWithMultipleRounds || errors.tourneeOrderByDeliveryDayWithMultiplePlcs || errors.tourneePlcByDeliveryDayWithMultipleOrders;
    this.displayErrors = anyErrors;
    this.errors = errors;
    return anyErrors;
  }

  openDialogSubscription = (): void => {
    this.subOpenDialog$ = this.orgTourneeSvc.openDialogEdition$
      .subscribe((orga: OrganisationTourneeInfoDto) => {
        this.loadFilters();

        this.displayDialog = true;
        if (!orga) {
          this.organisationTournees = new OrganisationTourneeInfoDto();
          this.dialogTitle = this.i8nSvc.getLabelFromCode('TITLE_CREATION_ORGANIZATION_ROUND', null);
        } else {
          this.organisationTournees = orga;
          this.idSelectedSite = orga.site.id;
          this.dialogTitle = this.i8nSvc.getLabelFromCode('TITLE_MODIFICATION_ORGANIZATION_ROUND', null);
        }
      });
  }

  onExporting = (e: any, day: string) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(day);

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
      customizeCell: function(options) {
        const { gridCell, excelCell } = options;

        if (gridCell.rowType === 'data') {
          if (gridCell.column.dataField === 'prestation.libelle') {
            excelCell.value =  gridCell.value ? gridCell.value : 'Toutes'
          }
          if (gridCell.column.dataField === 'famillesPlats') {
            excelCell.value =  gridCell.value.length ? gridCell.value?.map(p => p.libelle).join(',') : 'Toutes'
          }
          if (gridCell.column.dataField === 'plats') {
            excelCell.value = gridCell.value.length ? gridCell.value?.map(p => p.code).join(',') : 'Tous'
          }
        }
      }
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), this.organisationTournees.label + ' (' + day + ')' + '.xlsx');
      });
    });
  };

  findAllLocalSites = (): SiteDTO[] => {
    return this.auth2Svc.utilisateur.siteListLocaux;
  }

  closeDialog = (): void => {
    this.organisationTournees = null;
    this.displayDialog = false;
  }

  onChangeSite = ($event: any): void => {
    if(!this.organisationTournees.site)
      this.organisationTournees.site = new SiteDTO();
    this.organisationTournees.site.id = $event.selectedItem?.id;
  }

  openDialogPeriods = (): void => {
    this.displayPopupPeriods = true;
  }

  closeDialogPeriods = (): void => {
    this.displayPopupPeriods = false;
  }

  addTournee = (): void => {
    this.tourneeSvc.save({ site: {id: this.idSelectedSite } as SiteDTO, actif: true, libelle: this.labelTourneeToCreate, poidsMaxEssieux: this.poidsMaxTourneeToCreate } as Tournee)
      .subscribe((res) => {
        this.loadTournees();
        this.labelTourneeToCreate = null;
        this.poidsMaxTourneeToCreate = 0;
        this.displayPopupAddTournee = false;
        this.inputTournees.instance.open();
      });
  }

  addPeriod = (): void => {
    if (!this.organisationTournees.periods.find(o => o.start === this.dateStart.getTime() && o.end === this.dateEnd.getTime() ))
      this.organisationTournees.periods.push({ start: this.dateStart.getTime(), end: this.dateEnd.getTime() } as RoundPeriodsDto);
    this.displayPopupPeriods = false;
  }

  onChangeDayToDuplicate = ($event): void => {
    if ($event.value)
      this.daysWeekTarget = this.daysWeek.filter(d => d !== $event.value);
  }

  duplicateDay = (): void => {
    if (this.dayToDuplicateSelected && this.daysTargetForDuplication?.length) {
      this.daysTargetForDuplication.forEach(dayTarget => {
        let copyDayToDuplicateSelected: InfosRoundDto[] = JSON.parse(JSON.stringify(this.organisationTournees.informationsByDeliveryDay[this.dayToDuplicateSelected])); // la copie se fera pour chaque cible pour éviter des pb de références
        copyDayToDuplicateSelected.forEach(copy => {
          let idxTarget = this.daysWeek.findIndex(d => d === dayTarget);
          let idxOrigin = this.daysWeek.findIndex(d => d === this.dayToDuplicateSelected);
          let offset: number;
          if (idxOrigin > idxTarget) {
            let nbDaysInEndWeek = (this.daysWeek.length - 1) - idxOrigin;
            let nbDaysInStartWeek = idxTarget + 1;
            offset = nbDaysInEndWeek + nbDaysInStartWeek;
          } else {
            offset = idxTarget - idxOrigin;
          }
          // Je bloque besoin d'aide :
          copy.consumptionDay = this.daysWeek[((this.daysWeek.findIndex(d => d === copy.consumptionDay) + offset) % this.daysWeek.length)];
          copy.deliveryDay = dayTarget;
        });
        this.organisationTournees.informationsByDeliveryDay[dayTarget] = copyDayToDuplicateSelected;
      });
      this.displayPopupDuplicate = false;
    }
  }

  onChangePointLivraisonClient = ($event): void => {
    if ($event.value) {
      this.plcSelected = $event.value;
      this.repas = this.filtersCtx.allPrestationRoundContext.filter(item => item.idPointLivraison === this.plcSelected.id).map(item => ({ id: item.idRepas, libelle: item.labelRepas } as RepasDTO));
      this.repas = [...new Map((this.repas).map((item: RepasDTO) => [item["id"], item])).values()] as RepasDTO[];
      this.currentConsumptionDays = this.filtersCtx.allConsumptionDaysByPlc[this.plcSelected.id];
      this.currentConsumptionDays = this.currentConsumptionDays.map(c => {
        let key: number = 1;
        switch (c) {
          case 'LUNDI':
            key = 1;
            break;
          case 'MARDI':
            key = 2;
            break;
          case 'MERCREDI':
            key = 3;
            break;
          case 'JEUDI':
            key = 4;
            break;
          case 'VENDREDI':
            key = 5;
            break;
          case 'SAMEDI':
            key = 6;
            break;
          case 'DIMANCHE':
            key = 7;
            break;
          default:
            break;
        }
        return {key, label: c.charAt(0).toUpperCase() + c.slice(1).toLowerCase()};
      })
        .sort((d1, d2) => d1.key - d2.key)
        .map(d => d.label);
    }
  }

  onChangeRepas = ($event): void => {
    // En changeant le repas, on ne garde que les prestations ayant ce repas
    const repasSelected: RepasDTO[] = $event.value;

    this.prestations = repasSelected.length ?
      this.filtersCtx.allPrestationRoundContext.filter(item => repasSelected.find(r => item.idPointLivraison === this.plcSelected.id && r.id === item.idRepas) ).map(item => ({ id: item.prestation.id, libelle: item.prestation.libelle } as ContratMenuConviveDTO)) :
      this.filtersCtx.allPrestationRoundContext.map(item => ({ id: item.prestation.id, libelle: item.prestation.libelle } as ContratMenuConviveDTO))
    ;
    this.prestations = [...new Map((this.prestations).map((item: ContratMenuConviveDTO) => [item["id"], item])).values()] as ContratMenuConviveDTO[];
  }

  onDropDownBoxValueChanged(e: any) {
    this.updateSelection(this.treeView && this.treeView.instance);
  }

  onTreeViewReady(e: any) {
    this.updateSelection(e.component);
  }

  updateSelection(treeView: any) {
    if (!treeView) return;

    if (!this.treeBoxValue) {
      treeView.unselectAll();
    }

    if (this.treeBoxValue) {
      this.treeBoxValue.forEach(((value) => {
        treeView.selectItem(value);
      }));
    }
  }

  onTreeViewSelectionChanged(e: any) {
    this.treeBoxValue = e.component.getSelectedNodeKeys();
  }

  onTagBoxValueChanged(e: any) {
    if (!this.treeView) return;
    this.treeView.instance.unselectAll();
    this.updateSelection(this.treeView.instance);
  }

  displayFamillesPlats = (row: InfosRoundDto): string => row?.famillesPlats.length ? row?.famillesPlats?.map(p => p.libelle).join(",") : 'Toutes';

  displayPlats = (row: InfosRoundDto): string => row?.plats.length ? row?.plats?.map(p => p.code).join(",") : 'Tous';

  addRowsEnabled = (): boolean => this.tourneeSelected && this.plcSelected && this.jourLivSelected && this.jourConsoSelected.length > 0 && this.repasSelected.length > 0

  addRows = (): void => {
    this.orgTourneeSvc.prepareRow({
      tourneeSelected: this.tourneeSelected,
      orderSelected: this.orderSelected || 1,
      plcSelected: this.plcSelected,
      jourLivSelected: this.jourLivSelected,
      jourConsoSelected: this.jourConsoSelected,
      repasSelected: this.repasSelected,
      prestationsSelected: this.prestationsSelected,
      famillesPlats: (this.treeBoxValue && this.treeBoxValue.length) ? this.allFamillesProduits.filter(f => this.treeBoxValue.includes(f.id)).map(f => { delete(f.selected); return f; }) : [],
      codesPlats: this.codesPlats
    } as FormInfosRound, (jourLivSelected: InformationsByDeliveryDayDto, info: InfosRoundDto) => this.checkRows(jourLivSelected, info));

    this.clear();
  }

  private clear = (): void => {
    this.tourneeSelected = undefined;
    this.orderSelected = undefined;
    this.jourLivSelected = undefined;
    this.jourConsoSelected = [];
    this.repasSelected = [];
    this.prestationsSelected = [];
    this.treeDataSource = undefined;
    this.treeBoxValue = [];
    this.codesPlats = '';
    this.errors = undefined;
    this.warnings = undefined;
    if (this.treeView) this.treeView.instance.unselectAll();
  }

  equals = (info: InfosRoundDto, other: InfosRoundDto): boolean => {
    if (!other) return false;
    let sameFamillesPlats: boolean = true, samePlats: boolean = true;
    if (info.famillesPlats?.length === other.famillesPlats?.length) {
      for (let i = 0; i < info.famillesPlats.length; i++)
        if (other.famillesPlats[i].libelle !== other.famillesPlats[i].libelle) { sameFamillesPlats = false; break; }
    } else {
      sameFamillesPlats = false;
    }

    if (info.plats?.length === other.plats?.length) {
      for (let i = 0; i < info.plats.length; i++)
        if (other.plats[i].libelle !== other.plats[i].libelle) { samePlats = false; break; }
    } else {
      samePlats = false;
    }

    return info.consumptionDay === other.consumptionDay && info.deliveryDay === other.deliveryDay && info.order === other.order && info.pointLivraison?.id === other.pointLivraison?.id &&
      info.prestation?.libelle === other.prestation?.libelle && info.repas?.id === other.repas?.id && info.tournee?.id === other.tournee?.id && sameFamillesPlats && samePlats;
  }

  startDateChanged = ($event: any) => {
    this.dateStart = $event.value;
    this.minDate = new Date(this.dateStart.getTime());
    if(this.minDate > this.dateEnd) this.dateEnd = null;
  }

  endDateChanged = ($event: any) => this.dateEnd = $event.value;
}
