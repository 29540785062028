<dx-popup
  [showTitle]="true"
  [title]="title"
  [(visible)]="displayDialog"
  [showCloseButton]="true"
  width="80vw"
  height="70vh"
  (onHidden)="closeDialog()"
  [fullScreen]="fullScreen"
  [resizeEnabled]="!fullScreen"
  [dragEnabled]="!fullScreen"
>
  <dxi-toolbar-item
    widget="dxButton"
    location="after"
    [options]="{
            icon: 'fullscreen',
            onClick: toggleFullScreen
        }">
  </dxi-toolbar-item>


  <dx-scroll-view showScrollbar="always">

    <form *ngIf="catalogueAchat" (ngSubmit)="saveCatalogueAchat()" #articleForm="ngForm">

      <div id="produitArticleForm" class="container d-flex">

        <div class="col-6">
          <div class="title-ca">Article</div>
          <div class="container-catalogArticle">

            <div class="dialog-line-equal">
              <div class="label" title="Libellé">Site</div>
              <div class="value">
                <yo-lookup
                  #site
                  [data]="siteList"
                  placeholder="Site"
                  [selectedItem]="catalogueAchat.site.id"
                  [showClearButton]="true"
                  [autoSelect]="false"
                  [disabled]="!canModifyForUpdate()">
                </yo-lookup>
              </div>
            </div>

            <div *ngIf="catalogueAchat.id !== 0" class="dialog-line-equal">
              <div class="label" title="Code API">Code API</div>
              <div class="value">
                <dx-text-box
                  readOnly="true"
                  class='inputfield'
                  [(ngModel)]="catalogueAchat.codeApi"
                  #code="ngModel"
                  name="code"
                >
                </dx-text-box>
              </div>
            </div>

            <div class="dialog-line-equal">
              <div class="label" title="Libellé">Libellé <span class="danger-color">*</span></div>
              <div class="value">
                <dx-text-box
                  required
                  class='inputfield'
                  [(ngModel)]="catalogueAchat.produitArticle.libelle"
                  #libelle="ngModel"
                  name="libelle"
                  [disabled]="isDisabled()"
                >
                </dx-text-box>
              </div>
            </div>

            <div class="dialog-line-equal">
              <div class="label" title="Produit déclinaison">Produit déclinaison <span class="danger-color">*</span>
              </div>
              <div class="value">
                <yo-lookup
                  #produitDeclinaison
                  [data]="declinaisonsList"
                  [selectedItem]="catalogueAchat.produitArticle.produitDeclinaison.id"
                  [disabled]="isDisabled()"
                >
                </yo-lookup>
              </div>
            </div>

            <div class="dialog-line-equal">
              <div class="label" title="Référence article fournisseur">Réf. art. fournisseur <span
                class="danger-color">*</span>
              </div>
              <div class="value">
                <dx-text-box
                  required
                  class='inputfield'
                  [(ngModel)]="catalogueAchat.referenceArticle"
                  #referenceArticle="ngModel"
                  name="referenceArticle"
                  [disabled]="isDisabled()"
                >
                </dx-text-box>
              </div>
            </div>

            <div class="dialog-line-equal actif-container">
              <div class="label" title="Actif">Actif</div>
              <div class="value">
                <dx-check-box
                  [(ngModel)]="catalogueAchat.actif"
                  #actif="ngModel"
                  name="actif"
                  [disabled]="isDisabled()"
                >
                </dx-check-box>
              </div>
            </div>

            <div class="dialog-line-equal actif-container">
              <div class="label" title="Produit carné">Produit carné</div>
              <div class="value">
                <dx-check-box
                  [(ngModel)]="catalogueAchat.produitCarne"
                  #carne="ngModel"
                  name="carne"
                >
                </dx-check-box>
              </div>
            </div>


            <ng-container *ngIf="catalogueAchat.produitCarne">
              <div class="dialog-line-equal">
                <div class="label" title="Libellé">Naissance</div>
                <div class="value">
                  <yo-lookup
                    #naissance
                    [data]="origines"
                    [placeholder]="getOriginePlaceholder('de naissance', catalogueAchat?.origineAnimale?.naissance?.id)"
                    [selectedItem]="catalogueAchat?.origineAnimale?.naissance?.id"
                    [showClearButton]="true"
                    [autoSelect]="false">
                  </yo-lookup>
                </div>
              </div>
              <div class="dialog-line-equal">
                <div class="label" title="Élevage">Élevage</div>
                <div class="value">
                  <yo-lookup
                    #elevage
                    [data]="origines"
                    [placeholder]="getOriginePlaceholder('d\'élevage', catalogueAchat?.origineAnimale?.elevage?.id)"
                    [selectedItem]="catalogueAchat?.origineAnimale?.elevage?.id"
                    [showClearButton]="true"
                    [autoSelect]="false">
                  </yo-lookup>
                </div>
              </div>
              <div class="dialog-line-equal">
                <div class="label" title="Abattage">Abattage</div>
                <div class="value">
                  <yo-lookup
                    #abattage
                    [data]="origines"
                    [placeholder]="getOriginePlaceholder('d\'élevage', catalogueAchat?.origineAnimale?.abattage?.id)"
                    [selectedItem]="catalogueAchat?.origineAnimale?.abattage?.id"
                    [showClearButton]="true"
                    [autoSelect]="false">
                  </yo-lookup>
                </div>
              </div>
            </ng-container>
          </div>
          <div class="title-ca">Facturation</div>
          <div class="container-catalogArticle">
            <div class="dialog-line-equal">
              <div class="label" title="Unité de facturation">Unité de facturation <span
                class="danger-color">*</span>
              </div>
              <div class="value">
                <yo-lookup
                  #uniteDeFacturation
                  (onValueChange)="onChange($event, 'uniteDeFacturation')"
                  [data]="unitesDeMesure"
                  [selectedItem]="catalogueAchat.uniteDeFacturation.id"
                  [disabled]="isDisabled()"
                >
                </yo-lookup>
              </div>
            </div>

            <div class="dialog-line-equal">
              <div class="label" title="Unité de facturation / KG">Unité de facturation / KG <span
                class="danger-color">*</span>
              </div>
              <div class="input-group input-group-sm">
                <div class="input-group-append ">
                        <span class="input-group-text">1 {{ catalogueAchat?.uniteDeFacturation?.libelle }}
                          équivaut à</span>
                </div>
                <input type="number"
                       [min]="0"
                       [disabled]="isDisabled()"
                       class="form-control form-control-sm typeCycle"
                       [(ngModel)]="catalogueAchat.ratioUniteFacturationUniteBase"
                       #ratioUniteFacturationUniteBase="ngModel"
                       name="ratioUniteFacturationUniteBase"
                       (ngModelChange)="onChangeRatioUC()">
                <div class="input-group-append ">
                  <span class="input-group-text">KG</span>
                </div>
              </div>
            </div>

            <div class="dialog-line-equal">
              <div class="label" title="Prix d'achat">Prix d'achat <span class="danger-color">*</span></div>
              <div class="input-group input-group-sm">
                <input type="number"
                       [min]="0"
                       [disabled]="isDisabled()"
                       class="form-control form-control-sm typeCycle"
                       [(ngModel)]="catalogueAchat.prix"
                       (ngModelChange)="onPriceUpdated()"
                       #prix="ngModel"
                       name="prix">
                <div class="input-group-append ">
                  <span class="input-group-text">€ par {{ catalogueAchat.uniteDeFacturation.libelle }}</span>
                </div>
              </div>
            </div>

            <div class="dialog-line-equal" *ngIf="catalogueAchat.id != 0">
              <div class="custom-button-container-right">
                <yo-button
                  iconClass="fas fa-calendar"
                  buttonClass="cta-primary"
                  [ngClass]="catalogueAchat.caByPeriod.length ? 'active-period' : ''"
                  label="Afficher les périodes de prix"
                  (onClick)="openDialogPeriodPrice()"
                  tooltip="Afficher les périodes de prix"></yo-button>
              </div>
            </div>
          </div>
          <div class="title-ca">Stockage</div>
          <div class="container-catalogArticle">
            <div class="dialog-line-equal">
              <div class="label" title="Unité de stockage">Unité de stockage <span class="danger-color">*</span>
              </div>
              <div class="value">
                <yo-lookup
                  #uniteStockage
                  [data]="unitesDeMesure"
                  [disabled]="isDisabled()"
                  (onValueChange)="onChange($event, 'uniteDeStockage')"
                  [selectedItem]="catalogueAchat.uniteDeStockage.id"
                >
                </yo-lookup>
              </div>
            </div>

            <div class="dialog-line-equal">
              <div class="label" title="Unité de stockage / unité de technique">Unité de stockage / unité
                technique
                <span class="danger-color">*</span>
              </div>
              <div class="input-group input-group-sm">
                <div class="input-group-append ">
                          <span class="input-group-text">1 {{ catalogueAchat?.uniteDeStockage?.libelle }}
                            équivaut à</span>
                </div>
                <input type="number"
                       [min]="0"
                       class="form-control form-control-sm typeCycle"
                       [(ngModel)]="ratioUSUT"
                       (ngModelChange)="onChangeRatioUS()"
                       [ngModelOptions]="{standalone: true}"
                       [disabled]="isSameUSUT || isDisabled()">
                <div class="input-group-append ">
                  <span class="input-group-text">{{ catalogueAchat.uniteTechnique.libelle }}</span>
                </div>
              </div>
            </div>

            <div class="dialog-line-equal">
              <div class="label" title="Unité de stockage / KG">Unité de stockage / KG
                <span
                  class="danger-color">*</span>
              </div>
              <div class="input-group input-group-sm">
                <div class="input-group-append ">
                          <span class="input-group-text">1 {{ catalogueAchat?.uniteDeStockage?.libelle }}
                            équivaut à</span>
                </div>
                <input type="number"
                       [min]="0"
                       [disabled]="isDisabled()"
                       class="form-control form-control-sm typeCycle"
                       [(ngModel)]="catalogueAchat.ratioUniteStockageUniteBase"
                       #ratioUniteStockageUniteBase="ngModel"
                       name="ratioUniteStockageUniteBase"
                       (ngModelChange)="onChangeRatioUSUT()">
                <div class="input-group-append ">
                  <span class="input-group-text">KG</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="title-ca">Livré par <span
            class="danger-color">*</span></div>
          <div class="container-catalogArticle">
            <div class="dialog-line-equal">
              <dx-data-grid
                height="auto"
                (onSelectionChanged)="onChangeSelectedFournisseur($event)"
                showRowLines="true"
                showColumnLines="true"
                [dataSource]="fournisseursList"
              >
                <dxo-filter-row [visible]="true"></dxo-filter-row>
                <dxo-selection *ngIf="this.catalogueAchat.id == 0" mode="single"></dxo-selection>
                <dxi-column dataField="libelle" [allowFiltering]="this.catalogueAchat.id == 0 ? true : false">
                </dxi-column>
                <dxi-column dataField="code" [allowFiltering]="this.catalogueAchat.id == 0 ? true : false">
                </dxi-column>

              </dx-data-grid>
            </div>
          </div>
          <div class="title-ca">Commande</div>
          <div class="container-catalogArticle">
            <div class="dialog-line-equal">
              <div class="label" title="Unité de commande">Unité de commande <span class="danger-color">*</span>
              </div>
              <div class="value">
                <yo-lookup
                  #uniteDeCommande
                  (onValueChange)="onChange($event, 'uniteDeCommande')"
                  [data]="unitesDeMesure"
                  [selectedItem]="catalogueAchat.uniteDeCommande.id"
                  [disabled]="isDisabled()"
                >
                </yo-lookup>
              </div>
            </div>

            <div class="dialog-line-equal">
              <div class="label" title="Unité de commande / unité de facturation">Unité de commande / unité de
                facturation
                <span class="danger-color">*</span>
              </div>
              <div class="input-group input-group-sm">
                <div class="input-group-append ">
                        <span class="input-group-text">1 {{ catalogueAchat?.uniteDeCommande?.libelle }}
                          équivaut à</span>
                </div>
                <input type="number"
                       [min]="0"
                       class="form-control form-control-sm typeCycle"
                       [(ngModel)]="ratioUCUF"
                       (ngModelChange)="onChangeRatioUC()"
                       [ngModelOptions]="{standalone: true}"
                       [disabled]="isSameUCUF || isDisabled()"
                >
                <div class="input-group-append ">
                  <span class="input-group-text">{{ catalogueAchat.uniteDeFacturation.libelle }}</span>
                </div>
              </div>
            </div>

            <div class="dialog-line-equal">
              <div class="label" title="Unité de commande / KG">Unité de commande / KG
                <span
                  class="danger-color">*</span>
              </div>
              <div class="input-group input-group-sm">
                <div class="input-group-append ">
                        <span class="input-group-text">1 {{ catalogueAchat?.uniteDeCommande?.libelle }}
                          équivaut à</span>
                </div>
                <input type="number"
                       [min]="0"
                       [disabled]="isDisabled()"
                       class="form-control form-control-sm typeCycle"
                       [(ngModel)]="catalogueAchat.ratioUniteCommandeUniteBase"
                       #ratioUniteCommandeUniteBase="ngModel"
                       name="ratioUniteCommandeUniteBase"
                       (ngModelChange)="onChangeRatioUCUF()">
                <div class="input-group-append ">
                  <span class="input-group-text">KG</span>
                </div>
              </div>
            </div>

            <div class="dialog-line-equal">
              <div class="label" title="Conditionné par">Conditionné par</div>
              <div class="input-group input-group-sm">
                <input type="number"
                       [min]="0"
                       class="form-control form-control-sm typeCycle"
                       [(ngModel)]="catalogueAchat.conditionnePar"
                       #conditionnePar="ngModel"
                       name="conditionnePar">
                <div class="input-group-append ">
                  <span class="input-group-text"> {{ catalogueAchat.uniteDeCommande.libelle }}</span>
                </div>

              </div>
            </div>

            <div class="dialog-line-equal">
              <div class="label" title="Minimum à commander">Minimum à commander</div>
              <div class="input-group input-group-sm">
                <input type="number"
                       [min]="0"
                       class="form-control form-control-sm typeCycle"
                       [(ngModel)]="catalogueAchat.minimumDeCommande"
                       #minCommande="ngModel"
                       name="minCommande">
                <div class="input-group-append ">
                  <span class="input-group-text">{{ catalogueAchat.uniteDeCommande.libelle }}</span>
                </div>
              </div>
            </div>
          </div>


          <div id="colAllergeneAppellation" class="col">
            <div *ngIf="catalogueAchat.id !== 0" class="dialog-line-large">
              <div class="label" title="Allergènes"><span class="title-tagBox">Allergènes</span></div>
              <div class="value">
                <dx-tag-box
                  searchEnabled="true"
                  searchExpr="libelle"
                  [showSelectionControls]="true"
                  applyValueMode="useButtons"
                  [maxDisplayedTags]="3"
                  [multiline]="false"
                  [showMultiTagOnly]="false"
                  placeholder="Sélection, recherche..."
                  searchMode="contains"
                  [dataSource]="allergeneList"
                  [value]="produitAllergenes"
                  displayExpr="libelle"
                  valueExpr="id"
                  itemTemplate="customItem"
                  (onValueChanged)="onAllergenesChange($event)"
                >
                  <div *dxTemplate="let product of 'customItem'">
                    <div class="custom-item row">
                      <div class="col-2">
                        <yo-img-entity entityName="allergene"
                                       [entityId]="product.id"
                                       [ordre]="1"
                                       [height]="48"
                                       format="thumb"
                        ></yo-img-entity>
                      </div>
                      <div class="col-6">
                        <div class="product-name">
                          {{ product.libelle }}
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="product-name">
                          {{ product.code }}
                        </div>
                      </div>
                    </div>
                  </div>
                </dx-tag-box>
              </div>
            </div>

            <div *ngIf="catalogueAchat.id !== 0" class="dialog-line-large">
              <div class="label" title="Appellations"><span class="title-tagBox">Appellations</span></div>
              <div class="value">
                <dx-tag-box
                  searchEnabled="true"
                  searchExpr="libelle"
                  [showSelectionControls]="true"
                  applyValueMode="useButtons"
                  [maxDisplayedTags]="3"
                  [multiline]="false"
                  [showMultiTagOnly]="false"
                  placeholder="Sélection, recherche..."
                  searchMode="contains"
                  [dataSource]="allAppellations"
                  [value]="appellationsProduit"
                  displayExpr="libelle"
                  valueExpr="id"
                  itemTemplate="customItem"
                  (onValueChanged)="onAppellationsChange($event)"
                >
                  <div *dxTemplate="let product of 'customItem'">
                    <div class="custom-item row">
                      <div class="col-2">
                        <yo-img-entity entityName="appellation"
                                       [entityId]="product.id"
                                       [ordre]="1"
                                       [height]="48"
                                       format="thumb"
                        ></yo-img-entity>
                      </div>
                      <div class="col-8">
                        <div class="product-name">
                          {{ product.libelle }}
                        </div>
                      </div>
                      <div class="col-2">
                        <div class="product-name">
                          {{ product.code }}
                        </div>
                      </div>
                    </div>
                  </div>
                </dx-tag-box>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="dialog-line-equal">
        <div class="custom-button-container-right">
          <yo-button
            type="submit"
            iconClass="fas fa-save"
            tooltip="Enregistrer"
            buttonClass="cta-success"
            [disabled]="!isFormValid() || (!canModifyForUpdate() && this.catalogueAchat.id != null)">
          </yo-button>
          <yo-button
            buttonClass="cta-delete"
            (onClick)="closeDialog()"
            tooltip="Fermer"
            iconClass="fas fa-times"
          >
          </yo-button>
        </div>
      </div>

    </form>
  </dx-scroll-view>
</dx-popup>

<yo-period-price></yo-period-price>
