<dx-popup
  [width]="1200"
  [height]="'auto'"
  [maxHeight]="780"
  [title]="'Gérer la grille'"
  [showTitle]="true"
  (onHiding)="closeDialog()"
  [(visible)]="openDialog"
  [resizeEnabled]="true">
  <form [formGroup]="form" (ngSubmit)="save()" novalidate>

    <div class="fiche-id-size">
      <div class="row">
        <div class="col">
          <div class="title-associations-mcpCv row">Associations modèles conditionnements plats (conditionnements variantes)
            / prestations
          </div>
          <div class="container-associations-mcpCv row">
            <div class="col-md-6">
              <div class="input-group-sm ">
                <dx-list
                  #list
                  [dataSource]="mcpCvList"
                  [selectedItems]="mcpCvSelectedList"
                  [height]="350"
                  [style.width]="'100%'"
                  [searchEnabled]="true"
                  [selectionMode]="'all'"
                  [grouped]="true"
                  [collapsibleGroups]="true"
                  [showSelectionControls]="true"
                  searchMode="contains"
                  displayExpr="label"
                  keyExpr="value"
                  (onSelectionChanged)="mcpCvSelectionChanged($event)"
                >
                  <dxo-search-editor-options
                    placeholder="Conditionnements *">
                  </dxo-search-editor-options>
                </dx-list>
              </div>
            </div>

            <div class="col-md-6">
              <div class="input-group-sm ">
                <dx-list
                  #list
                  [dataSource]="prestationsList"
                  [selectedItems]="prestationsSelectedList"
                  [height]="350"
                  [style.width]="'100%'"
                  [searchEnabled]="true"
                  [selectionMode]="'all'"
                  [showSelectionControls]="true"
                  searchMode="contains"
                  searchExpr="libelle"
                  displayExpr="libelle"
                  keyExpr="id"
                  (onSelectionChanged)="prestationSelectionChanged($event)"
                >
                  <dxo-search-editor-options
                    placeholder="Prestations *">
                  </dxo-search-editor-options>
                </dx-list>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-between mg-t-10">
        <div>
          <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
        </div>
        <yo-button
          title="Enregistrer"
          type="submit"
          iconClass="fas fa-save"
          buttonClass="cta-success"
          [disabled]="!canModify"></yo-button>
      </div>

    </div>
  </form>
</dx-popup>
