// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[master]6.1-10538(1c38a5aaa5)-C04/09/2024-22:46:58-B04/09/2024-22:55:22' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[master]6.1-10538(1c38a5aaa5)-C04/09/2024-22:46:58-B04/09/2024-22:55:22",
  branch: "master",
  latestTag: "6.1",
  revCount: "10538",
  shortHash: "1c38a5aaa5",
  longHash: "1c38a5aaa527cc472a19651efefceb0812294aad",
  dateCommit: "04/09/2024-22:46:58",
  dateBuild: "04/09/2024-22:55:22",
  buildType: "Ansible",
  } ;
