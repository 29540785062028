import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import ModeleConditionnementPlcDTO from "../../../dtos/conditionnement/plc/modele-conditionnement-plc-dto";
import {Subject} from "rxjs/index";
import {HttpService} from "../../technique/http.service";
import {ResponseWrapper} from "../../../suppliers/wrappers/response-wrapper";
import ResteDTO from "../../../dtos/conditionnement/reste-dto";
import {ConviveDTO} from "../../../dtos/convive-dto";

export const URL_GET_ALL_MODELES_PLC = `dolrest/conditionnements/modeles-conditionnements-plc/list`;
export const URL_GET_MODELE_PLC = `dolrest/conditionnements/modeles-conditionnements-plc/findById`;
export const SAVE_PARAMETRAGE_MODELE_PLC = `dolrest/conditionnements/modeles-conditionnements-plc/saveParametrage`;
export const URL_SAVE_ROW_PARAMETRAGE = `dolrest/conditionnements/modeles-conditionnements-plc/saveRowParametrage`;
export const URL_DUPLICATE_MODELE = `dolrest/conditionnements/modeles-conditionnements-plc/duplicate`;

@Injectable({
  providedIn: 'root'
})
export class ModelesPlcService {

  private subMcPlcSaved = new Subject<MpPlcSavedSupplier>();
  mcPlcSaved$ = this.subMcPlcSaved.asObservable();

  private subCreationRowParametrageAsked = new Subject<ModeleConditionnementPlcDTO>();
  creationRowParametrageAsked$ = this.subCreationRowParametrageAsked.asObservable();

  constructor(private httpSvc: HttpService) { }

  /**
   * Récupérer tous les modèles de conditionnement pour des points de livraison
   * @returns {Observable<ModeleConditionnementPlatDTO>}
   */
  getAllModelesConditionnementsPlc = (): Observable<ResponseWrapper<ModeleConditionnementPlcDTO>> => this.httpSvc.get(URL_GET_ALL_MODELES_PLC, null);

  findById = (id: number): Observable<ResponseWrapper<ModeleConditionnementPlcDTO>> => this.httpSvc.get(`${URL_GET_MODELE_PLC}/${id}`, null);

  /**
   * Enregistre un modèle plc
   * @param data modèle plc
   */
  save = (data: ModeleConditionnementPlcDTO) => this.httpSvc.post(`dolrest/conditionnements/modeles-conditionnements-plc/save`, data);

  delete = (ids: number[]) => this.httpSvc.delete(`dolrest/conditionnements/modeles-conditionnements-plc/delete?ids=${ids}`);

  duplicateModele = (modeleIdToDuplicate: number, duplicationSiteSelectedId: number) => this.httpSvc.post(`${URL_DUPLICATE_MODELE}/${modeleIdToDuplicate}/${duplicationSiteSelectedId}`, null);

  formatDataParametrage = (grid: any, mappingColumnsPrestations: Map<number, number>, queuesConfiguration: any, idMcplc: number): ModelePlcParametrage => {
    grid.forEach(g => {

      g.data = [];
      Object.keys(g.prestations).forEach(col => {
        let idPrestation = null;
        const column = g.prestations[col];
        const idCol = parseInt(col, 10);
        Object.keys(column).forEach(c => {
          const idRow = parseInt(c, 10);
          idPrestation = mappingColumnsPrestations.get(idCol);
          Object.keys(column[idRow]).forEach(rowcol => {
            const info = g.prestations[idCol][idRow];
            const {actif, mcplc__prestaid, mcplc__mcpCvid} = info;
            if (g.data.findIndex(i => i.idPrestation === idPrestation && i.mcPlcPrestaMcPlcMcpCvId === info.mcPlcPrestaMcPlcMcpCvId) < 0) {
              g.data.push({
                idPrestation,
                mcPlcPrestaMcPlcMcpCvId: info.mcPlcPrestaMcPlcMcpCvId,
                actif,
                mcplc__prestaid,
                mcplc__mcpCvid
              });
            }
          });
        });
      });

      delete g.prestations;

    });

    const data: ModelePlcParametrage = {
      idMcplc,
      grid
    };

    return data;
  }

  /**
   * Enregistre le paramètrage de la grille du modèle plc
   * @param data
   */
  saveParametrage = (data: ModelePlcParametrage) => this.httpSvc.post(SAVE_PARAMETRAGE_MODELE_PLC, data);

  /**
   * Annonce l'enregistrement d'un modèle plc
   * @param mcp modèle plc enregistré
   * @param isUpdate
   */
  announceMcPlcSaved = (mcPlc: ModeleConditionnementPlcDTO, isUpdate: boolean = false) => {
    this.subMcPlcSaved.next({ mcPlc, isUpdate } as MpPlcSavedSupplier);
  };

  /**
   * Annonce la création d'une ligne de paramétrage
   * @param mcplc (Ne doit jamais être renseigné si on respecte le cas général)
   */
  announceCreationRowParametrageAsked = (mcplc?: ModeleConditionnementPlcDTO) => {
    this.subCreationRowParametrageAsked.next(mcplc);
  };

  /**
   * Enregistre le paramétrage d'une ligne de la grille
   * @param modelePlc Le modèle conditionnement plc concerné par la configuration
   * @param mcpCvList L'ensemble des modèles conditionnement plat <-> conditionnement variante à gérer
   * @param prestationsList L'ensemble des prestations à gérer
   */
  saveRowParametrage = (modelePlc: ModeleConditionnementPlcDTO, idsMcpCvList: number[], idsPrestationsList: number[]) => {
    return this.httpSvc.post(URL_SAVE_ROW_PARAMETRAGE, {
      modelePlcId: modelePlc.id,
      idsMcpCvList,
      idsPrestationsList
    });
  };

}

export interface MpPlcSavedSupplier {
  isUpdate: boolean;
  mcPlc: ModeleConditionnementPlcDTO;
}

export interface ModelePlcParametrage {
  grid: any; // GridModelePlcParametrage
  idMcplc: number;
}

export interface GridModelePlcParametrage {
  mcpLabel: string;
  conditionnementLabel: string;
  varianteLabel: string;
  idmcPlcMcpCV: number;
  id: number;
  data: DataGridModelePlcParametrage[];
}

export interface DataGridModelePlcParametrage {
  idPrestation: number;
  mcPlcPrestaMcPlcMcpCvId: number;
  mcplc__prestaid: number;
  mcplc__mcpCvid: number;
  actif: boolean;
}
