<div class="flex-container">
  <div class="custom-toolbar">
    <div class="custom-toolbar-subdivision-left custom-toolbar-element">
      <yo-button
        class="mg-r-10"
        iconClass="fas fa-cog"
        label="GÉRER LA GRILLE"
        tooltip="Gérer la grille"
        [disabled]="!hasIDistri"
        (onClick)="openCreateRowParametrage()">
      </yo-button>
    </div>
    <div class="custom-toolbar-subdivision-right">
      <yo-button
        tooltip="Enregistrer"
        type="submit"
        iconClass="fas fa-save"
        buttonClass="cta-success"
        (onClick)="save()"
        [disabled]="!hasIDistri">
      </yo-button>
    </div>
  </div>

  <div class="" *ngIf="mode === 'PIECE_NON_PROPORTIONNELLE'">
    <label class="bold">Nombre de {{ uniteAConditionnerLibelle }} à servir au convive :</label>
    <dx-data-grid
      [dataSource]="[declinaisonList]"
      width="100%"
      height="100px"
      [hoverStateEnabled]="true"
      [allowColumnResizing]="true"
      [rowAlternationEnabled]="true"
      [showRowLines]="true"
      [showBorders]="true"
      columnResizingMode="widget"
      #gridUc>
      <dxi-column *ngFor="let declinaison of declinaisonList"
                  cellTemplate="poidsNetTemplate"
                  [width]="150"
                  [caption]="declinaison.libelle">
      </dxi-column>
      <div *dxTemplate="let cell of 'poidsNetTemplate'">
        <dx-number-box [min]="0" (onValueChanged)="changeNbUc($event, cell, true)" (onFocusIn)="onFocusIn($event)"
                       [value]="declinaisonsNbUCList[cell.columnIndex].nbUc"></dx-number-box>
      </div>
    </dx-data-grid>
  </div>
  <dx-data-grid
    [dataSource]="gridParametrage"
    keyExpr="id"
    width="100%"
    class="full-size-grid"
    [allowColumnResizing]="true"
    [hoverStateEnabled]="true"
    [rowAlternationEnabled]="true"
    [showRowLines]="true"
    [showBorders]="true"
    #grid>
    <dxi-column dataField="conditionnementLabel" caption="Conditionnement" [width]="130"
                [filterOperations]="['contains']"
                [allowFiltering]="true" [fixed]="true" fixedPosition="left">
    </dxi-column>
    <dxi-column dataField="varianteLabel" caption="Variante" [width]="130"
                [filterOperations]="['contains']"
                [allowFiltering]="true" [fixed]="true" fixedPosition="left">
    </dxi-column>
    <dxi-column *ngFor="let declinaison of declinaisonList"
                dataField="declinaison.id"
                cellTemplate="declinaisonCellTemplate"
                [width]="350"
                [caption]="declinaison.libelle">
    </dxi-column>
    <dxi-column dataField="tache" caption="Type de conditionnement"
                cellTemplate="tacheCellTemplate"
                [width]="250"
                [allowFiltering]="false">
    </dxi-column>
    <dxi-column dataField="remarque" caption="Commentaires"
                cellTemplate="tacheCellCommentaires"
                [width]="200"
                [filterOperations]="['contains']"
                [allowFiltering]="true">
    </dxi-column>

    <dxo-summary>
      <dxi-total-item
        *ngFor="let declinaison of declinaisonList"
        name="SelectedRowsSummary"
        showInColumn="declinaison">
      </dxi-total-item>
    </dxo-summary>

    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-sorting mode="multiple"></dxo-sorting>

    <dxo-pager
      [showPageSizeSelector]="true"
      [showNavigationButtons]="false"
      [allowedPageSizes]="[20, 50, 100, 200]"
      [visible]="true"
      [showInfo]="true"
      infoText="{2} conditionnements">
    </dxo-pager>

    <div *dxTemplate="let cell of 'declinaisonCellTemplate'" style="display: grid">

      <div *ngIf="mode === 'PIECE_PROPORTIONNELLE' || mode === 'PIECE_NON_PROPORTIONNELLE' " class="d-inline-flex align-items-center">
        <span style="display: inline-block;width: 100px;">Effectif nourri :</span>
        <dx-number-box class="ml-2 mr-2" [min]="0" (onFocusIn)="onFocusIn($event)" style="width: 80px" [showSpinButtons]="true"
                       (onValueChanged)="changeEffectif($event, cell, true)"
                       [value]="cell.row.data.declinaisons[cell.columnIndex][cell.row.data.id].effectifNourri"></dx-number-box>
      </div>

      <div *ngIf="mode === 'PIECE_NON_PROPORTIONNELLE'" class="d-inline-flex align-items-center">
        <dx-number-box class="ml-2 mr-2" [min]="0" (onFocusIn)="onFocusIn($event)" style="width: 80px" [showSpinButtons]="true" [readOnly]="true"
                       [value]="cell.row.data.declinaisons[cell.columnIndex][cell.row.data.id].contient"></dx-number-box>
        <span> {{ uniteAConditionnerLibelle }} nourri(ssent) {{ cell.row.data.declinaisons[cell.columnIndex][cell.row.data.id].effectifNourri }} convive(s)</span>
      </div>

      <div *ngIf="mode === 'PESEE'" class="d-inline-flex align-items-center">
        <span>Poids net : </span>
        <dx-number-box class="ml-2 mr-2" [min]="0" (onFocusIn)="onFocusIn($event)" style="width: 80px" [showSpinButtons]="true"
                       [(ngModel)]="cell.row.data.declinaisons[cell.columnIndex][cell.row.data.id].poidsNet"></dx-number-box>
        <span> {{ uniteAConditionnerLibelle }}</span>
      </div>

    </div>

    <div *dxTemplate="let cell of 'tacheCellTemplate'">
      <dx-select-box
        [dataSource]="tachesList"
        displayExpr="libelle"
        valueExpr="id"
        [(ngModel)]="cell.row.data.idtache"
        [value]="cell.row.data.idtache || tachesList[0].id">
      </dx-select-box>
    </div>

    <div *dxTemplate="let cell of 'tacheCellCommentaires'">
      <div>
        <dx-text-area [(ngModel)]="cell.row.data.remarque"></dx-text-area>
      </div>
    </div>

  </dx-data-grid>
  <dx-data-grid
    [dataSource]="[footerList]"
    width="100%"
    [hoverStateEnabled]="true"
    [allowColumnResizing]="true"
    [rowAlternationEnabled]="true"
    [showRowLines]="true"
    [showBorders]="true"
    #grid>
    <dxi-column dataField="conditionnementLabel" caption="" [width]="130">
    </dxi-column>
    <dxi-column dataField="varianteLabel" caption="" [width]="130">
    </dxi-column>
    <dxi-column *ngFor="let declinaison of declinaisonList"
                cellTemplate="queueCellTemplate"
                [width]="250"
                [caption]="declinaison.libelle">
    </dxi-column>
    <dxi-column dataField="tache" caption="" [width]="250">
    </dxi-column>
    <dxi-column dataField="remarque" caption="" [width]="200">
    </dxi-column>

    <div *dxTemplate="let cell of 'queueCellTemplate'">
      <dx-select-box
        [dataSource]="resteList"
        displayExpr="libelle"
        valueExpr="id"
        (onValueChanged)="changeQueue($event, cell.columnIndex)"
        [value]="queuesConfiguration[cell.columnIndex] && queuesConfiguration[cell.columnIndex].reste ? queuesConfiguration[cell.columnIndex].reste.id : resteList[2].id">
      </dx-select-box>
    </div>

  </dx-data-grid>

  <div>
    <div class="mr-auto ">
      <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
      <span class="mg-r-5 font-12">N'oubliez pas d'enregistrer votre travail</span>
    </div>
  </div>
  <yo-mp-add-cv-decli></yo-mp-add-cv-decli>
</div>
