import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {confirm} from "devextreme/ui/dialog";
import {UtilsService} from "../../../core/utils/utils.service";
import {ToastService} from "../../../core/services/technique/toast.service";
import {InternationalizationService} from "../../../core/services/i8n/i8n.service";
import {Auth2Service} from "../../../core/services/security/auth2.service";
import {CommunicationProduitDTO} from "../../../core/dtos/referentiel/base.communication.produit/communication-produit-dto";
import {CommunicationProduitService} from "../../../core/services/entities/referentiel.base/communication-produit-service";
import {MSG_KEY, MSG_SEVERITY} from "../../../core/constants";

@Component({
  selector: 'yo-communication-produit',
  templateUrl: './communication-produit.component.html',
  styleUrls: ['./communication-produit.component.scss']
})
export class CommunicationProduitComponent implements OnInit, OnDestroy {

  subLog: Subscription;
  subCommunicationProduitSaved: Subscription;
  subCommunicationProduit: Subscription;
  deleteSub: Subscription;

  logged: boolean = false;

  communicationProduitList: CommunicationProduitDTO[] = [];

  selectedRows: number[] = [];
  errors: any[] = [];

  displayDeletePopupErrors: boolean = false;

  pathFile: string = null;// HELP_FOLDERS.REFERENTIEL + '/communicationProduit/communicationProduit';

  constructor(public readonly communicationProduitSvc: CommunicationProduitService,
              public readonly utils:UtilsService,
              private readonly toastSvc: ToastService,
              private readonly i8nSvc: InternationalizationService,
              private readonly auth2Svc: Auth2Service) {
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subLog);
    this.utils.unsubscribe(this.subCommunicationProduitSaved);
    this.utils.unsubscribe(this.subCommunicationProduit);
    this.utils.unsubscribe(this.deleteSub);
  }

  ngOnInit(): void {
    this.initCommunicationProduitsSubscription();
    this.initCommunicationProduitsSavedSubscription();
  }

  openDialogEdition = (communicationProduit?: CommunicationProduitDTO): void => {
    this.communicationProduitSvc.announceOpenDialogEdition(communicationProduit);
  };

  calculateSiteValue = (communicationProduit: CommunicationProduitDTO): String => {
    return communicationProduit?.site?.libelle
  };

  calculateTypeProduitValue = (communicationProduit: CommunicationProduitDTO): String => {
    return communicationProduit?.typeProduit?.libelle
  };

  initCommunicationProduitsSubscription = (): void => {
    this.subCommunicationProduit = this.communicationProduitSvc.findFamillesEgalim().subscribe(response => {
      this.communicationProduitList = response;
    });
  }

  initCommunicationProduitsSavedSubscription = (): void => {
    this.subCommunicationProduitSaved = this.communicationProduitSvc.communicationProduitSaved$
      .subscribe((famille) => {
        let familleFound = this.communicationProduitList.find(f => f.id == famille.id);
        if (familleFound)
          this.communicationProduitList[this.communicationProduitList.indexOf(familleFound)] = famille;
        else
          this.communicationProduitList.push(famille);
      });
  };

  displayDeleteErrors = (): void => {
    this.displayDeletePopupErrors = true;
  }

  closeErrors = (): void => {
    this.errors = [];
    this.displayDeletePopupErrors = false;
  }


  deleteValues = async (): Promise<void> => {
    this.errors = [];
    let idsToDeleteInTable = Object.assign([], this.selectedRows);

    let isDeletionValidated: boolean = await confirm(this.i8nSvc.getLabelFromCode(idsToDeleteInTable.length > 1 ? "CONFIRM_DELETION_PLURAL" : "CONFIRM_DELETION_SINGULAR", null), this.i8nSvc.getLabelFromCode("Suppression", null));

    if (!isDeletionValidated)
      return new Promise(null);

    this.deleteSub = this.communicationProduitSvc.deleteByFilters(this.selectedRows).subscribe(response => {
      const res: any = response.one;

      if (res) {
        if (res.messagesErrorList?.length) {
          for (const error of res.messagesErrorList) {
            const infosLine: string = this.i8nSvc.getLabelFromCode(error.code, error.args.split(','));
            this.errors.push({infosLine});
            const elementToKeep = this.communicationProduitList.find(communicationProduit => communicationProduit.code === error.args);
            idsToDeleteInTable = idsToDeleteInTable.filter(id => elementToKeep.id !== id);
          }
          this.displayDeleteErrors();
        } else {
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, this.i8nSvc.getLabelFromCode(idsToDeleteInTable.length > 1 ? `DELETION_COMPLETED_PLURAL` : `DELETION_COMPLETED_SINGULAR`, null));
        }
        this.communicationProduitList = this.communicationProduitList.filter(communicationProduit => !idsToDeleteInTable.find(id => id === communicationProduit.id));
      }
    });
  }

  canDelete = (): boolean => this.selectedRows.length > 0;

}
