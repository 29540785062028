import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UtilsService} from '../../../../core/utils/utils.service';
import {ToastService} from "../../../../core/services/technique/toast.service";
import {
  PreparationConditionnementService
} from "../../../../core/services/conditionnements/preparation-conditionnement.service";
import {Subscription} from "rxjs";
@Component({
  selector: 'yo-print-settings-editions-conditionnement',
  templateUrl: './print-settings-editions-conditionnement.component.html',
  styleUrls: ['./print-settings-editions-conditionnement.component.scss']
})
export class PrintSettingsEditionsConditionnementComponent implements OnInit, OnDestroy {

  constructor(public utils: UtilsService,
              private toastSvc: ToastService,
              private preparationConditionnementSvc: PreparationConditionnementService) {
  }

  readonly PAYSAGE: string = 'PAYSAGE';
  readonly PORTRAIT: string = 'PORTRAIT';
  private subDisplayDialog: Subscription;

  @Input('idPlanProduction') idPlanProduction: number;

  displayDialog: boolean = false;

  printDetails: boolean = true;

  formatPrinting: string = this.PORTRAIT;

  formatPrintingOptions: any[] = [{ label: 'Paysage', value: this.PAYSAGE }, { label: 'Portrait', value: this.PORTRAIT }];

  ngOnInit(): void {
    this.subDisplayDialog = this.preparationConditionnementSvc.askPrintConditionnement$
      .subscribe(() => this.displayDialog = true);
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subDisplayDialog)
  }

  closeDialog = (): void => {
    this.displayDialog = false;
    this.formatPrinting = this.PORTRAIT;
    this.printDetails = true;
  }

  print = (): void => {
    this.preparationConditionnementSvc.printBonConditionnement(this.idPlanProduction, this.printDetails ? 'DETAILS' : 'SIMPLE', this.formatPrinting === this.PAYSAGE);
    this.closeDialog();
  }

}
