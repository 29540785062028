<dx-toolbar class="yo-toolbar">
  <dxi-item location="before">
    <div class="d-flex flex-row align-items-center">
      <yo-help lang="fr" [file]="pathHelpFile" [width]="800"></yo-help>
      <div class="mg-l-5 mg-r-5">
        <yo-button
          class="mg-r-10"
          iconClass="fa fa-plus"
          label="CRÉER"
          (onClick)="openDialogEdition()"
          tooltip="Créer une organisation de tournées">
        </yo-button>
      </div>
    </div>
  </dxi-item>
  <dxi-item location="after" class="mg-r-16">
    <yo-button
      iconClass="fas fa-trash"
      buttonClass="cta-delete"
      (onClick)="deleteValues()"
      [disabled]="!canDelete()"
      tooltip="Supprimer les éléments sélectionnés">
    </yo-button>
  </dxi-item>
</dx-toolbar>

<dx-data-grid
  [dataSource]="organisationsTournees"
  keyExpr="id"
  class="data-grid"
  [hoverStateEnabled]="true"
  [rowAlternationEnabled]="true"
  [allowColumnResizing]="true"
  [showRowLines]="true"
  [showBorders]="true"
  [(selectedRowKeys)]="selectedRows"
  columnResizingMode="widget"
  #grid>
  <dxo-selection
    selectAllMode="allPages"
    showCheckBoxesMode="always"
    mode="multiple"
  ></dxo-selection>
  <dxi-column dataField="id" caption="Actions"
              [width]="109"
              cssClass="text-align-center vertical-align-middle mg-r-0"
              [allowFiltering]="false"
              [allowGrouping]="false"
              cellTemplate="actionsCellTemplate">
  </dxi-column>
  <dxi-column dataField="site" caption="Site"
              cssClass="vertical-align-middle"
              [filterOperations]="['contains']"
              [allowFiltering]="true"
              cellTemplate="actionsSiteTemplate"
              [calculateCellValue]="calculateSiteValue">
  </dxi-column>
  <dxi-column dataField="label" caption="Libellé"
              cssClass="vertical-align-middle"
              [filterOperations]="['contains']"
              [allowFiltering]="true">
  </dxi-column>
  <dxi-column dataField="code" caption="Code"
              cssClass="vertical-align-middle"
              [filterOperations]="['contains']"
              [allowFiltering]="true">
  </dxi-column>
  <dxi-column dataField="periods" caption="Périodes"
              cssClass="vertical-align-middle"
              cellTemplate="periodeCellTemplate"
              [filterOperations]="['contains']"
              [allowFiltering]="true">
  </dxi-column>

  <div *dxTemplate="let cell of 'actionsCellTemplate'" class="custom-button-container">
    <yo-cell-button (yoNavigation)="openDialogEdition(cell.row.data)"
                    [yoTooltipShowDelay]="1500"
                    [yoTextAlign]="'center'"
                    [yoIconClass]="orgTourneesSvc.canModify(cell.row.data)? 'fa fa-edit' : 'fas fa-eye'"
                    [title]="orgTourneesSvc.canModify(cell.row.data)? 'Modifier l\'organisation' : 'Voir l\'organisation'">
    </yo-cell-button>
    <yo-cell-button (yoNavigation)="openPanelDuplication($event, cell.row.data)"
                    [yoTooltipShowDelay]="1500"
                    [yoTextAlign]="'center'"
                    yoIconClass='fas fa-copy'
                    title='Dupliquer'>
    </yo-cell-button>
  </div>

  <div *dxTemplate="let cell of 'actionsSiteTemplate'">
    <yo-site [siteLibelle]="cell.row.data?.site?.libelle" [siteId]="cell.row.data?.site?.id"></yo-site>
  </div>

  <div *dxTemplate="let cell of 'periodeCellTemplate'">
    {{ showPeriodes(cell.row.data) }}
  </div>

  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-sorting mode="multiple"></dxo-sorting>

  <dxo-pager
    [showPageSizeSelector]="true"
    [showNavigationButtons]="false"
    [allowedPageSizes]="[20, 50, 100, 200]"
    [visible]="true"
    [showInfo]="true"
    infoText="{2} organisation(s)">
  </dxo-pager>

</dx-data-grid>

<yo-organisation-tournee-dialog></yo-organisation-tournee-dialog>

<dx-popup
  [showTitle]="true"
  title="Lignes n'ayant pas été supprimées"
  [(visible)]="displayDeletePopupErrors"
  [height]="'auto'"
  [resizeEnabled]="true"
  [showCloseButton]="true"
  (close)="closeErrors()"
>
  <label>Les éléments non présents dans cette liste ont bien été supprimés.</label>
  <dx-data-grid
    [dataSource]="errors"
    keyExpr="infosLine"
    [hoverStateEnabled]="true"
    [rowAlternationEnabled]="true"
    [allowColumnResizing]="true"
    [showRowLines]="true"
    [showBorders]="true"
    columnResizingMode="widget"
  >
    <dxi-column dataField="infosLine" caption="Ligne non supprimée" [filterOperations]="['contains']" [allowFiltering]="true"></dxi-column>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-pager
      [showPageSizeSelector]="true"
      [showNavigationButtons]="false"
      [allowedPageSizes]="[20, 50, 100, 200]"
      [visible]="true"
      [showInfo]="true"
      infoText="{2} erreur(s)">
    </dxo-pager>
  </dx-data-grid>
</dx-popup>


<!--PANEL DUPLICATION-->
<p-overlayPanel #opDuplication>
  <div class="row">
    <div class="col-md-12">
      <div class="form-group ">
        <label
          [class.requiredControl]="true"
          class="genlabel  font-16"
          title=""
        >Site sur lequel dupliquer le produit</label>
        <div class="col-sm-10">
          <p-dropdown [options]="auth2Svc.utilisateur.siteListLocaux"
                      optionLabel="libelle"
                      filter="true"
                      [(ngModel)]="duplicationSiteSelected"
                      placeholder="sélection..."
                      [autoDisplayFirst]="false"
                      [style]="{'width':'360px'}">
            <ng-template let-site pTemplate="selectedItem">
          <span style="vertical-align:middle; margin-left: .5em">
            <yo-site [siteId]="site.id" [siteLibelle]="site.libelle"></yo-site></span>
            </ng-template>
            <ng-template let-site pTemplate="item">
              <div style="position: relative;height: 25px;">
                <div style="font-size:14px;margin-top:4px">
                  <yo-site [siteId]="site.id" [siteLibelle]="site.libelle"></yo-site>
                </div>
              </div>
            </ng-template>
          </p-dropdown>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 text-right">
      <yo-button
        label="Dupliquer"
        iconClass="fas fa-copy"
        (onClick)="duplicateOrganisation()"
        [disabled]="duplicationSiteSelected === undefined ? true : false">
      </yo-button>
    </div>
  </div>
</p-overlayPanel>
