import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {Subject} from "rxjs/index";
import {map} from "rxjs/operators";
import {CommunicationProduitDTO} from "../../../dtos/referentiel/base.communication.produit/communication-produit-dto";
import {HttpService} from "../../technique/http.service";
import {Auth2Service} from "../../security/auth2.service";
import {GraphQLService} from "../../technique/graphql.service";
import {ObjectDTO} from "../../../dtos/object-dto";
import {ResponseWrapper} from "../../../suppliers/wrappers/response-wrapper";

@Injectable({
  providedIn: 'root'
})
export class CommunicationProduitService {

  private subjectCommunicationProduitSaved: Subject<any> = new Subject<any>();
  communicationProduitSaved$ = this.subjectCommunicationProduitSaved.asObservable();

  private subjectOpenDialog: Subject<CommunicationProduitDTO> = new Subject<CommunicationProduitDTO>();
  openDialog$ = this.subjectOpenDialog.asObservable();

  constructor(
    private httpSvc: HttpService,
    private auth2Svc: Auth2Service,
    private graphQlSvc: GraphQLService) {
  }

  findFamillesEgalim = (): Observable<any> => {
    const idsSites: number[] = this.auth2Svc.utilisateur.sites.map(s => s.id);
    return this.graphQlSvc.sendQuery(`
      {
        allCommunicationsProduit(filters: {
          siteIds: [${idsSites}]
          }) {
              id,
              site {
                id,
                libelle
              },
              typeProduit {
                id,
                libelle
              }
              code,
              libelle,
              actif,

          }
      }
    `).pipe(map(result => result.allCommunicationsProduit))
  }

  canModify(element: ObjectDTO): boolean {
    if (!element || !element?.site || !element?.site.id)
      return true;
    return this.auth2Svc.isSiteLocal(element.site.id);
  }

  deleteByFilters = (rowsToDelete?: number[]): Observable<ResponseWrapper<CommunicationProduitDTO>> => {
    let params: string = '1=1';
    if (rowsToDelete && rowsToDelete.length) params += `&ids=${rowsToDelete.join(',')}`
    return this.httpSvc.delete(`dolrest/referentiel/base/communication-produit/delete?${params}`);
  };

  announceOpenDialogEdition = (communicationProduit: CommunicationProduitDTO) => {
    this.subjectOpenDialog.next(communicationProduit);
  };

  announceCommunicationProduitSaved = (communicationProduit: CommunicationProduitDTO) => {
    this.subjectCommunicationProduitSaved.next(communicationProduit);
  }

  save = (communicationProduit: CommunicationProduitDTO): Observable<ResponseWrapper<CommunicationProduitDTO>> => {
    return this.httpSvc.post("dolrest/referentiel/base/communication-produit/save", communicationProduit);
  }

}
