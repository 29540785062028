import {BehaviorSubject, Observable, Subject} from 'rxjs';

import {catchError, filter} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {GenericHandler} from '../generics/generic-handler';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {FormFieldCheckboxSupplier} from '../../suppliers/form-field-checkbox-supplier';
import {FormFieldTextboxSupplier} from '../../suppliers/form-field-textbox-supplier';
import {UtilsService} from '../../utils/utils.service';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Router} from '@angular/router';
import {Auth2Service} from '../security/auth2.service';
import {Title} from '@angular/platform-browser';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {ProduitDTO} from '../../dtos/produit-dto';
import {FormFieldDropdownSupplier} from '../../suppliers/form-field-dropdown-supplier';
import {UnitesDeMesuresService} from './unites-de-mesures.service';
import {ResponseWrapper} from '../../suppliers/wrappers/response-wrapper';
import {TypesProduitService} from './types-produit.service';
import {FamillesProduitService} from './familles-produit.service';
import {TypeProduitDTO} from '../../dtos/type-produit-dto';
import {ObjectDTO} from '../../dtos/object-dto';
import {SiteDTO} from '../../dtos/site-dto';
import * as uuid from 'uuid';
import {GUID_HELP, PREDICAT_OPERATOR, PREDICAT_TYPE} from '../../constants';
import {FormGroup} from '@angular/forms';
import {FormFieldFileSupplier} from '../../suppliers/form-field-file-supplier';
import {ProduitRouteMapService} from '../gestionproduits/produit-routemap.service';
import {ProduitModeOperatoireDTO} from '../../dtos/produit-mode-operatoire.dto';
import {ProduitDeclinaisonDTO} from '../../dtos/produit-declinaison-dto';
import {UniteDeMesureDTO} from '../../dtos/unitedemesure-dto';
import {find as _find, startCase as _startCase} from 'lodash';
import {ZonesDeStockageService} from './zones-de-stockage.service';
import {RegimeAlimentaireService} from './regime-alimentaire.service';
import {TextureService} from './texture.service';
import {ContrainteModel} from '../../models/contrainte-model';
import {GenericRequestSupplier, Predicat, Search} from '../../suppliers/generics/generic-request-supplier';
import {ProduitContrainteAlimService} from './produit-contrainte-alim.service';
import {GenericDatagridService} from '../generics/generic-datagrid.service';
import {ProduitContrainteAlimDTO} from '../../dtos/produit-contrainte-alim-dto';

import {ProduitnomenclatureDTO, UDM_TYPE} from '../../dtos/produitnomenclature-dto';
import {ProduitAllergeneDTO} from '../../dtos/produit-allergene-dto';
import {RequestWrapper} from '../../suppliers/wrappers/request-wrapper';
import {SearchSupplierWrapper} from '../../suppliers/wrappers/search-supplier-wrapper';
import {MPrintFicheTechniqueDTO} from '../../../gestion-produits/produit/fichetechnique-produit/produitsrecette/model-print-pdf-ft';
import {SearchSupplier} from '../../suppliers/search-supplier';
import {GenericFormService} from '../generics/generic-form.service';
import {FormFieldTreeSupplier} from '../../suppliers/form-field-tree-supplier';
import {TachesService} from './taches.service';
import {TYPE_TACHE} from './type-tache.service';
import {TacheDTO} from '../../dtos/tache-dto';
import {HttpService} from '../technique/http.service';
import {Produit__TacheDTO} from '../../dtos/produit-tache-dto';
import {IsDeletableObject} from "../../models/is-deletable-object";

export const URL_POST_DUPLICATE_PRODUIT_PLAT = `dolrest/gestionproduits/duplicate/produit`;
export const URL_POST_PRINT_FICHE_TECHNIQUE = `dolrest/gestionproduits/produit/pdf/fiche-technique`;
export const URL_GET_PRODUIT = `dolrest/gestionproduits/produits`;
export const URL_GET_MODE_OPERATOIRES = `dolrest/gestionproduits/produit/get/modesOperatoires`;
export const URL_POST_PRODUIT_SEARCH = `dolrest/gestionproduits/produits/search`;
export const URL_POST_SAVE_PRODUIT = `dolrest/gestionproduits/produit/save`;
export const URL_GET_PRODUCTS_BY_FILTERS = 'dolrest/gestionproduits/produits/search-by-filters';

/**
 * @deprecated Trop lié à ce comportement Hibernate back-end (GenericHandler) et d'entities Hibernate, ce n'est pas un pur service.
 * Merci d'utiliser la classe ProduitService.
 */
@Injectable()
export class ProduitsService extends GenericHandler<ProduitDTO> {

  private globalFilterSubject = new BehaviorSubject<SearchSupplierWrapper>(undefined);
  globalFilter$ = this.globalFilterSubject.asObservable().pipe(filter(item => !!item));

  private produitSubjectToDelete = new BehaviorSubject<ProduitDTO>(undefined);
  produitSubjectToDelete$ = this.produitSubjectToDelete.asObservable().pipe(filter(item => !!item));

  private paginationSubject = new BehaviorSubject<string>(undefined);
  // Observable produit streams
  pagination$ = this.paginationSubject.asObservable().pipe(filter(item => !!item));

  private dialogBatchSubject = new Subject<any>();
  dialogBatchSubject$ = this.dialogBatchSubject.asObservable();

  constructor(utils: UtilsService, auth2Svc: Auth2Service, router: Router, http: HttpClient, title: Title, private unitesDeMesuresService: UnitesDeMesuresService,
              private typesProduitSvc: TypesProduitService,
              private tacheSvc: TachesService,
              private famillesProduitSvc: FamillesProduitService,
              private produitRouteMapSvc: ProduitRouteMapService,
              private produitContrainteAlimSvc: ProduitContrainteAlimService,
              private regimeSvc: RegimeAlimentaireService,
              private gds: GenericDatagridService,
              private textureSvc: TextureService,
              private zdsSvc: ZonesDeStockageService,
              private produitRoutemapSvc: ProduitRouteMapService,
              private gfs: GenericFormService,
              private httpSvc: HttpService,
              private prmSvc: ProduitRouteMapService
  ) {
    super(utils, auth2Svc, router, http, title);

  }

  getTotalRecordsLabel = (): string => _startCase(this.getEntityName());


  getHelp = (): DialogMsgSupplier => undefined;

  getOas = (): boolean => undefined;

  getSort = (): string[] => ['libelle,asc'];

  getTitle = (): string => 'GESTION DES PRODUITS';

  getCreateNewObjectLabel = (): string => 'CRÉER UN PRODUIT';

  getEntityName = (): string => 'produit';


  getTacheOfProduit = (produit: ProduitDTO, typeTache: TYPE_TACHE): TacheDTO => {
    let tache;

    if (!this.utils.isNullOrEmpty(produit) && !this.utils.isCollectionNullOrEmpty(produit.produit__tacheList)) {
      const pt = _find(produit.produit__tacheList, {'typeTacheCode': typeTache});
      if (!this.utils.isNullOrEmpty(pt)) {
        tache = new TacheDTO();
        tache.id = pt.tacheId;
        tache.code = pt.tacheCode;
        tache.libelle = pt.libelle;
        tache.typeTacheId = pt.typeTacheId;
        tache.typeTacheCode = pt.typeTacheCode;
        tache.typeTacheLibelle = pt.typeTacheLibelle;
      }

    }

    return tache;
  };

  getFieldsOfDenree = (dto: ProduitDTO): FormFieldBaseSupplier<any>[] => {

    const isReadonly = !this.canModify(dto);
    const typesProduits = this.typesProduitSvc.getTypesProduits(this.typesProduitSvc.typeProduitList, false);

    let famillesProduitsDenree = [];
    if (!this.utils.isCollectionNullOrEmpty(this.famillesProduitSvc.familleProduitList)) {
      famillesProduitsDenree = this.famillesProduitSvc.familleProduitList.filter(item => item.fabrique === false);
    }

    const tacheApprovisionnement = this.getTacheOfProduit(dto, TYPE_TACHE.APPROVISIONNEMENT);
    const tacheSortie = this.getTacheOfProduit(dto, TYPE_TACHE.SORTIE);


    let formFields: FormFieldBaseSupplier<any>[] = [

      new FormFieldDropdownSupplier({
        key: 'site',
        label: 'Site',
        readonly: !this.canModifySite(dto) || dto.id != 0,
        type: 'externalRef',
        value: this.utils.preSelectSingleList(this.optionsSites, dto.site),
        order: 1,
        options: this.optionsSites,
        required: true
      }),

      new FormFieldDropdownSupplier({
        key: 'typeProduit',
        label: 'Type de produit',
        readonly: isReadonly,
        required: true,
        type: 'externalRef',
        // value: selectedTypeProduit,
        value: this.utils.preSelectSingleList(typesProduits, dto.typeProduit),
        order: 10,
        options: typesProduits
      }),
      new FormFieldTreeSupplier({
        key: 'familleProduit',
        label: 'Famille de produit',
        required: true,
        readonly: isReadonly,
        type: 'tree',
        cols: [
          {header: 'Famille de produit', field: 'libelle'},
        ],
        tableHeight: 200,
        value: dto.familleProduit,
        order: 12,
        options: famillesProduitsDenree
      }),
      new FormFieldDropdownSupplier({
        key: 'uniteDeMesure',
        label: 'Unité technique',
        readonly: isReadonly,
        type: 'externalRef',
        required: true,
        value: this.utils.preSelectSingleList(this.unitesDeMesuresService.unitesDeMesureEnvironnement, dto.uniteDeMesure),
        order: 8,
        options: this.unitesDeMesuresService.unitesDeMesureEnvironnement,
        linkedFieldFn: this.changeRatioUniteTechnique
      }),

      new FormFieldTextboxSupplier({
        key: 'ratioVolume',
        label: 'Ratio Volume',
        help: `Ratio volume / masse (Litre / KG)`,
        readonly: isReadonly,
        value: dto.ratioVolume,
        required: ProduitsService.isRatioVolumeRequired(dto.uniteDeMesure),
        min: 0.0001,
        width: 150,
        prefix: `1 LITRE équivaut à`,
        suffix: 'KG',
        type: ProduitsService.getTypeRatioVolume(dto.uniteDeMesure),
        order: 9
      }),

      new FormFieldDropdownSupplier({
        key: 'zoneDeStockage',
        label: 'Zone de stockage',
        required: true,
        readonly: isReadonly,
        type: 'externalRef',
        value: this.utils.preSelectSingleList(this.zdsSvc.zdsEnvironnement, dto.zoneDeStockage),
        order: 18,
        options: this.zdsSvc.zdsEnvironnement
      }),
      new FormFieldTextboxSupplier({
        key: 'dlc',
        label: 'Date limite de conso.',
        prefix: '+',
        suffix: 'jour(s)',
        help: 'Privilégier le produit selon sa date de consommation',
        readonly: isReadonly,
        value: dto.dlc,
        required: true,
        width: 80,
        type: 'number',
        order: 12
      }),
      new FormFieldTextboxSupplier({
        key: 'libelle',
        label: 'Libellé',
        readonly: isReadonly,
        minLength: 2,
        maxLength: 100,
        value: dto.libelle,
        required: true,
        order: 4
      }),
      new FormFieldTextboxSupplier({
        key: 'libellelong',
        label: 'Libellé complet',
        readonly: isReadonly,
        minLength: 1,
        maxLength: 255,
        value: dto.libellelong,
        required: true,
        order: 5
      }),
      new FormFieldTextboxSupplier({
        key: 'code',
        label: 'Code',
        help: `Code, utilisé pour communiquer avec d'autres logiciels`,
        readonly: false,
        value: dto.code,
        maxLength: 50,
        required: false,
        order: 6
      }),

      new FormFieldDropdownSupplier({
        key: 'tacheApprovisionnement',
        label: `Type d'approvisionnement`,
        required: true,
        readonly: isReadonly,
        type: 'externalRef',
        value: this.utils.preSelectSingleList(this.tacheSvc.tacheApprovisionnementList, tacheApprovisionnement),
        order: 11,
        options: this.tacheSvc.tacheApprovisionnementList
      }),

      new FormFieldDropdownSupplier({
        key: 'tacheSortie',
        label: `Type de sortie`,
        required: true,
        readonly: isReadonly,
        type: 'externalRef',
        value: this.utils.preSelectSingleList(this.tacheSvc.tacheSortieList, tacheSortie),
        order: 11,
        options: this.tacheSvc.tacheSortieList
      }),

      new FormFieldFileSupplier({
        key: 'file',
        label: 'Photo',
        value: '',
        readonly: isReadonly,
        entityName: this.getEntityName().toLowerCase(),
        refresh: new Date().getTime(),
        ordre: 1,
        required: false,
        order: 99
      }),
      new FormFieldCheckboxSupplier({
        key: 'actif',
        label: 'Actif',
        readonly: isReadonly,
        value: dto.actif,
        required: false,
        order: 100
      }),
      new FormFieldTextboxSupplier({
        key: 'id',
        type: 'hidden',
        value: dto.id
      }),
      new FormFieldTextboxSupplier({
        key: 'guid',
        label: 'Guid',
        help: GUID_HELP,
        value: dto.guid,
        maxLength: 50,

        type: 'hidden',
        order: 3
      }),
    ];


    return formFields;

  };


  getFieldsOfPlat = (dto: ProduitDTO): FormFieldBaseSupplier<any>[] => {

    const isReadonly = !this.canModify(dto);
    const contraintesAlim: ContrainteModel[] = this.regimeSvc.getRegimesContraintes();
    let produitContraintesAlim = dto && dto.produitContrainteAlimList ? dto.produitContrainteAlimList : [];
    const typesProduits = this.typesProduitSvc.getTypesProduits(this.typesProduitSvc.typeProduitList, true);


    const tacheFabrication = this.getTacheOfProduit(dto, TYPE_TACHE.FABRICATION);


    let famillesProduitsPlat = [];
    if (!this.utils.isCollectionNullOrEmpty(this.famillesProduitSvc.familleProduitList)) {
      famillesProduitsPlat = this.famillesProduitSvc.familleProduitList.filter(item => item.fabrique === true);
    }

    let formFields: FormFieldBaseSupplier<any>[] = [

      new FormFieldDropdownSupplier({
        key: 'site',
        label: 'Site',
        readonly: !this.canModifySite(dto) || dto.id != 0,
        type: 'externalRef',
        value: this.utils.preSelectSingleList(this.optionsSites, dto.site),
        order: 1,
        options: this.optionsSites,
        required: true
      }),

      new FormFieldDropdownSupplier({
        key: 'typeProduit',
        label: 'Type de produit',
        readonly: isReadonly,
        required: true,
        type: 'externalRef',
        // value: selectedTypeProduit,
        value: this.utils.preSelectSingleList(typesProduits, dto.typeProduit),
        order: 10,
        options: typesProduits
      }),
      new FormFieldTreeSupplier({
        key: 'familleProduit',
        label: 'Famille de produit',
        required: true,
        readonly: isReadonly,
        type: 'tree',
        cols: [
          {header: 'Famille de produit', field: 'libelle'},
        ],
        tableHeight: 200,
        value: dto.familleProduit,
        order: 12,
        options: famillesProduitsPlat
      }),
      new FormFieldDropdownSupplier({
        key: 'uniteDeMesure',
        label: 'Unité technique',
        readonly: isReadonly,
        type: 'externalRef',
        required: true,
        value: this.utils.preSelectSingleList(this.unitesDeMesuresService.unitesDeMesureEnvironnement, dto.uniteDeMesure),
        order: 8,
        options: this.unitesDeMesuresService.unitesDeMesureEnvironnement,
        linkedFieldFn: this.changeRatioUniteTechnique
      }),

      new FormFieldTextboxSupplier({
        key: 'ratioVolume',
        label: 'Ratio Volume',
        help: `Ratio volume / masse (Litre / KG)`,
        readonly: isReadonly,
        value: dto.ratioVolume,
        required: ProduitsService.isRatioVolumeRequired(dto.uniteDeMesure),
        min: 0.0001,
        width: 150,
        prefix: `1 LITRE équivaut à`,
        suffix: 'KG',
        type: ProduitsService.getTypeRatioVolume(dto.uniteDeMesure),
        order: 9
      }),

      new FormFieldDropdownSupplier({
        key: 'tacheFabrication',
        label: 'Type de fabrication',
        required: true,
        readonly: isReadonly,
        type: 'externalRef',
        value: this.utils.preSelectSingleList(this.tacheSvc.tacheFabricationList, tacheFabrication),
        order: 11,
        options: this.tacheSvc.tacheFabricationList
      }),

      new FormFieldTextboxSupplier({
        key: 'ratioVolume',
        label: 'Ratio Volume',
        help: `Ratio volume / masse (Litre / KG)`,
        readonly: isReadonly,
        value: dto.ratioVolume,
        required: ProduitsService.isRatioVolumeRequired(dto.uniteDeMesure),
        min: 0.0001,
        width: 150,
        prefix: `1 LITRE équivaut à`,
        suffix: 'KG',
        type: ProduitsService.getTypeRatioVolume(dto.uniteDeMesure),
        order: 9
      }),

      new FormFieldTextboxSupplier({
        key: 'libelle',
        label: 'Libellé',
        readonly: isReadonly,
        minLength: 2,
        maxLength: 100,
        value: dto.libelle,
        required: true,
        order: 4
      }),
      new FormFieldTextboxSupplier({
        key: 'libellelong',
        label: 'Libellé complet',
        readonly: isReadonly,
        minLength: 1,
        maxLength: 255,
        value: dto.libellelong,
        required: true,
        order: 5
      }),
      new FormFieldDropdownSupplier({
        key: 'regimes',
        label: 'Régimes',
        help: 'Régime et Texture',
        required: true,
        readonly: isReadonly,
        type: 'multiple',
        value: this.regimeSvc.preselectContraintesAlim(contraintesAlim, produitContraintesAlim),
        order: 19,
        options: contraintesAlim
      }),
      new FormFieldTextboxSupplier({
        key: 'code',
        label: 'Code',
        help: `Code, utilisé pour communiquer avec d'autres logiciels`,
        readonly: false,
        value: dto.code,
        maxLength: 50,
        required: false,
        order: 6
      }),
      new FormFieldFileSupplier({
        key: 'file',
        label: 'Photo',
        value: '',
        readonly: isReadonly,
        entityName: this.getEntityName().toLowerCase(),
        refresh: new Date().getTime(),
        ordre: 1,
        required: false,
        order: 99
      }),
      new FormFieldCheckboxSupplier({
        key: 'actif',
        label: 'Actif',
        readonly: isReadonly,
        value: dto.actif,
        required: false,
        order: 100
      }),
      new FormFieldTextboxSupplier({
        key: 'id',
        type: 'hidden',
        value: dto.id
      }),
      new FormFieldTextboxSupplier({
        key: 'recetteNbPortions',
        type: 'hidden',
        value: dto.recetteNbPortions
      }),
      new FormFieldTextboxSupplier({
        key: 'guid',
        label: 'Guid',
        help: GUID_HELP,
        value: dto.guid,
        maxLength: 50,

        type: 'hidden',
        order: 3
      }),

    ];

    return formFields;

  };

  getFields = (dto: ProduitDTO): FormFieldBaseSupplier<any>[] => {


    let fields = [];


    switch (dto.typeProduit.fabrique) {
      // PLAT
      case true:
        fields = this.getFieldsOfPlat(dto);
        break;

      // DENREE
      case false:
        fields = this.getFieldsOfDenree(dto);
        break;
    }

    return fields.sort((a, b) => a.order - b.order);

  };


  getAllFromEnvironnement = (): void => {

  };

  changeRatioUniteTechnique = (value: string, form: FormGroup, fields: FormFieldBaseSupplier<any>[], object: any) => {

    if (fields) {

      const udm = object as UniteDeMesureDTO;
      for (let field of fields) {
        if (field.key === 'ratioVolume') {
          field.required = ProduitsService.isRatioVolumeRequired(udm);
          field.type = ProduitsService.getTypeRatioVolume(udm);
        }
      }
    }

  };

  /**
   * Si l'unite de mesure est de type volumeEnLitre alors le ratio volumeEnLitre est obligatoire
   * @param udm
   */
  static isRatioVolumeRequired = (udm: UniteDeMesureDTO) => {

    if (udm) {

      // si volumeEnLitre
      if (udm.type === 2) {
        return true;
      }

    }
    return false;
  };

  /**
   * On n'affiche le ratio volumeEnLitre que si l'unité de mesure est un volumeEnLitre
   * @param dto
   */
  static getTypeRatioVolume = (udm: UniteDeMesureDTO) => {

    if (udm) {

      // si volumeEnLitre
      if (udm.type === UDM_TYPE.VOLUME) {
        return 'number';
      }

    }

    return 'hidden';

  };

  createEmptyDTO = (): ProduitDTO => this.createEmptyProduitDTO(false);

  createEmptyProduitDTO = (fabrique: boolean): ProduitDTO => {
    const produit = new ProduitDTO();

    produit.id = 0;
    produit.site = new SiteDTO();
    produit.actif = true;
    produit.ratioVolume = 1;

    produit.typeProduit = new TypeProduitDTO();
    produit.typeProduit.fabrique = fabrique;
    produit.dlc = 0;

    // Initialiser les contraintes alimenataires par defaut
    produit.produitContrainteAlimList = this.regimeSvc.initContraintesAlimentaires(this.produitContrainteAlimSvc.createEmptyFromContrainte);

    produit.produit__tacheList = [];

    produit.guid = uuid();

    return produit;
  };


  getEditObjectLabel = (data: ObjectDTO): string => `MODIFIER LE PRODUIT '${data.libelle.toUpperCase()}'`;


  getProduitById = (idProduit: number) => {
    return this.http.get(URL_GET_PRODUIT, {
      params: new HttpParams()
        .set('idProduit', idProduit + '')
    }).pipe(
      catchError(error => this.utils.handleError(error, true))
    )
  };

  getModesOperatoiresByProduitId = (idProduit: number) => {
    return this.http.get(URL_GET_MODE_OPERATOIRES, {
      params: new HttpParams()
        .set('idProduit', idProduit + '')
    }).pipe(
      catchError(error => this.utils.handleError(error, true))
    )
  };

  applyMassiveTreatments = (params: any) => {
    return this.httpSvc.post(`dolrest/gestionproduits/applyMassiveTreatments`, params);
  }

  saveModeOperatoire = (idProduit: number, produitsModesOperatoires: ProduitModeOperatoireDTO[]) => {
    let url = this.produitRouteMapSvc.getSaveProduitsModesOperatoiresSLink(idProduit);
    return this.http.post<ResponseWrapper<ProduitModeOperatoireDTO>>(url, produitsModesOperatoires,).pipe(
      catchError(error => this.utils.handleError(error)),
    );
  };

  findProduitsModesOperatoires = (produitDeclinaison: ProduitDeclinaisonDTO) => {
    let url = this.produitRouteMapSvc.getModeOperatoireProduitDeclinaisonSLink(produitDeclinaison.id);
    return this.http.get(url,).pipe(
      catchError(error => this.utils.handleError(error, true))
    )

  };


  /**
   * Enregistrer les familles Gemrcn associées à un produit
   * @param {ProduitDeclinaisonDTO[]} produitDecliList
   * @returns {Observable<any>}
   */
  saveFamillesGemrcn = (produitDecliList: ProduitDeclinaisonDTO[]) => {
    return this.http.post(this.produitRouteMapSvc.getSaveFamillesGemrcn(), produitDecliList).pipe(
      catchError(error => this.utils.handleError(error, true)))
  };

  /**
   * Tri d'une liste de ProduitDeclinaisonDTO par libellé de déclinaison croissant puis par code croissant
   * @param listProduitDeclinesDTO
   */
  sortListeProduitDeclinesDTO = (listProduitDeclinesDTO: ProduitDeclinaisonDTO[]): ProduitDeclinaisonDTO[] => {
    let sortedArray: ProduitDeclinaisonDTO[];

    sortedArray = listProduitDeclinesDTO.sort((produitDeclinaison1, produitDeclinaison2) => {
      if (produitDeclinaison1.declinaison.libelle > produitDeclinaison2.declinaison.libelle) {
        return 1;
      } else if (produitDeclinaison1.declinaison.libelle < produitDeclinaison2.declinaison.libelle) {
        return -1;
      } else {
        if (produitDeclinaison1.code > produitDeclinaison2.code) {
          return 1;
        } else if (produitDeclinaison1.code < produitDeclinaison2.code) {
          return -1;
        } else {
          return 0;
        }
      }
    });

    return sortedArray;
  };

  /**
   * Tri d'une liste de ProduitModeOperatoireDTO par libellé de déclinaison croissant.
   * @param listeProduitModeOperatoireDTO
   */
  sortListeProduitModeOperatoireDTO = (listeProduitModeOperatoireDTO: ProduitModeOperatoireDTO[]): ProduitModeOperatoireDTO[] => {
    let sortedArray: ProduitModeOperatoireDTO[];

    sortedArray = listeProduitModeOperatoireDTO.sort((produitModeOperatoire1, produitModeOperatoire2) => {
      if (produitModeOperatoire1.libelleDeclinaison > produitModeOperatoire2.libelleDeclinaison) {
        return 1;
      } else if (produitModeOperatoire1.libelleDeclinaison < produitModeOperatoire2.libelleDeclinaison) {
        return -1;
      } else {
        return 0;
      }
    });

    return sortedArray;


  };


  /**
   * Récupérer toutes les contraintes alimentaires (regime + texture) du produit
   * @param idProduit
   */
  filterProduitContrainteAlim = (idProduit: number): GenericRequestSupplier => {

    const grs = new GenericRequestSupplier(this.produitContrainteAlimSvc.getEntityName());
    const search = new Search();

    const predicatProduit = new Predicat();
    predicatProduit.path = `${this.produitContrainteAlimSvc.getEntityName().toLowerCase()}.produit.id`;
    predicatProduit.operator = PREDICAT_OPERATOR.Equals;
    predicatProduit.type = PREDICAT_TYPE.Integer;
    predicatProduit.value = idProduit + '';

    search.predicats = [predicatProduit];

    grs.search = search;

    return grs;
  };

  /**
   * Dupliquer un produit de type produit plat
   * @param plat
   * @param site
   */
  duplicateProduitPlat = (plat: ProduitDTO, site: SiteDTO) => {

    const requestSupplier = new RequestWrapper();
    const fd = new FormData();

    plat.site = site;

    plat.produitContrainteAlimList = [];

    requestSupplier.entityName = this.getEntityName().toLowerCase();
    requestSupplier.one = plat;

    fd.set('myJson', JSON.stringify(requestSupplier));

    return this.httpSvc.post(URL_POST_DUPLICATE_PRODUIT_PLAT, fd);
  };

  printFicheTechnique = (instance: MPrintFicheTechniqueDTO) => {

    return this.http.post(URL_POST_PRINT_FICHE_TECHNIQUE, instance, {
      headers: new HttpHeaders().set('Accept', 'application/pdf'),
      responseType: 'blob'
    }).pipe(
      catchError(err => this.utils.handleError(err, true))
    );
  };

  announceGlobalFilter = (ssw: SearchSupplierWrapper) => {
    this.globalFilterSubject.next(ssw);
  };
  /**
   * Recherche des produits
   * @param {string} endUrl
   * @param {SearchSupplierWrapper} ssW
   * @returns {Observable<any>}
   */
  search: (endUrl: string, ssW: SearchSupplierWrapper, isNonFabrique?: boolean) => Observable<any> = (endUrl: string, ssW: SearchSupplierWrapper, isNonFabrique?: boolean) => {
    if (isNonFabrique)
      ssW.filtersMap['isFabrique'] = new SearchSupplier(false);
    return this.http.post(URL_POST_PRODUIT_SEARCH + `?${endUrl}`, ssW).pipe(
      catchError(error => this.utils.handleError(error, true))
    );
  };

  searchByFilters = (filters: any) => {
    const args = Object.keys(filters).map(key => key + '=' + filters[key]).join('&');
    return this.httpSvc.get(`${URL_GET_PRODUCTS_BY_FILTERS}?${args}`);
  }

  announceOpenDialogBatch = (): void => {
    this.dialogBatchSubject.next(null);
  }


  refreshProduitGrille = (produitsForGrille: ProduitDTO[], produit: ProduitDTO): ProduitDTO[] => {

    let arr = [];

    let isFoundProduitArr = produitsForGrille.filter(item => produit && item.id === produit.id);
    if (!this.utils.isCollectionNullOrEmpty(isFoundProduitArr)) {
      produitsForGrille.map(item => {
        if (produit && item.id === produit.id) {
          item = produit;
        }
        arr.push(item);
      });
    } else {
      produitsForGrille.unshift(produit);
      produitsForGrille.map(item => arr.push(item));
    }
    return arr;
  };

  public delete = (produitId: number): Observable<ResponseWrapper<IsDeletableObject>> => {
    const url = this.prmSvc.getDeleteProduitSLink(produitId);
    return this.httpSvc.delete(url, null);
  };

  /**
   * Destruction du {@link ProduitDTO} passé en paramètre.
   * @param produitDTO
   */
  public deleteProduit = (produitDTO: ProduitDTO): Observable<ResponseWrapper<any>> => this.delete(produitDTO.id);

  /**
   * Enregistrer un produit avec toutes ses dépendances : <br>
   *     la photo, les produit contraint alim associes, les produits taches associées
   *
   * @param produit
   * @param photo
   */
  saveProduit = (produit: ProduitDTO, photo: any) => {

    const fd = new FormData();
    fd.set('produitJson', JSON.stringify(produit));
    fd.set('photo', photo);

    return this.httpSvc.post(URL_POST_SAVE_PRODUIT, fd);

  };

  initTacheOfProduit = (produit: ProduitDTO, tache: TacheDTO): Produit__TacheDTO => {

    const pt = new Produit__TacheDTO();
    pt.produitId = produit.id;
    pt.tacheId = tache.id;
    pt.typeTacheId = tache.typeTacheId;
    pt.typeTacheCode = tache.typeTacheCode;

    return pt;
  };
}

export class ProduitSupplier {

  produitDeclinaisonDTOList: ProduitDeclinaisonDTO[];
  produitDTO: ProduitDTO;
  isFabrique: boolean;
  produitNomenclatureList: ProduitnomenclatureDTO[];
  produitDeclinaisonUtilises: ProduitDeclinaisonDTO[];
  produitAllergeneDTOList: ProduitAllergeneDTO[];
  produitContraintesAlim: ProduitContrainteAlimDTO[];
  idUdpMdp: number;

}
