import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {forEach as _forEach} from 'lodash';
import {BilanAllergeneSearchDto} from '../../../../core/dtos/gestionmenus/bilan-allergene-search-dto';
import {ContratMenuConvive__ContrainteAlimDTO} from '../../../../core/dtos/contratmenuconvive__contrainte-alim-dto';
import {SelectItem} from 'primeng/api';
import {UtilsService} from '../../../../core/utils/utils.service';
import {DATEPICKER_FR, DATES_FORMAT, MIME_TYPE, USER_PREFERENCE} from '../../../../core/constants';
import * as moment from 'moment';
import {saveAs as fs_saveAs} from 'file-saver';
import {of, Subscription} from 'rxjs';
import {PreferencesUtilisateurService} from '../../../../core/services/preferences-utilisateur.service';
import {PrintMenuService} from '../../../../core/services/gestionmenus/print-menu.service';
import {ContratMenuConviveDTO} from '../../../../core/dtos/contratmenuconvive-dto';
import {MenusCompositionService} from "../../../../core/services/gestionmenus/menus-composition.service";
import {RepasComposanteDTO} from "../../../../core/dtos/gestionmenus/repas-composantes-dto";
import {DxTreeListComponent} from "devextreme-angular";
import {newArray} from "@angular/compiler/src/util";


@Component({
  selector: 'yo-print-bilan-allergene',
  templateUrl: './print-bilan-allergene.component.html',
  styleUrls: ['./print-bilan-allergene.component.scss']
})
export class DialogPrintBilanAllergeneComponent implements OnInit, OnDestroy {

  @Input() public selectedStartDateMenus: Date;
  @Input() public contratsMenuConvives: ContratMenuConviveDTO[];
  @Input() public regimeList: ContratMenuConvive__ContrainteAlimDTO[];
  @ViewChild('treeList') treeList: DxTreeListComponent;

  startDate: Date;
  minDate: Date;
  endDate: Date;
  comment: string;
  selectedRegimes: SelectItem[] = [];
  regimeOptionList: SelectItem[] = [];
  fr = DATEPICKER_FR;
  displayDialog: boolean;
  selectedRows: number [] = [];

  subComment: Subscription;
  private subPlatsEtComposantesPlats: Subscription;
  private subRepasAndComposantesRepas: Subscription;
  public filteredRepasAndComposantesRepas: any[] = [];
  private selectedComposantesRepas: any[] = [];

  constructor(
    private printMenuService: PrintMenuService,
    private preferencesUtilisateurService: PreferencesUtilisateurService,
    public utils: UtilsService,
    private menuCompositionService: MenusCompositionService) {
  }

  ngOnInit(): void {
    this.printMenuService.displayDialogBilanAllergene$.subscribe((response: boolean) => {
      this.displayDialog = true;
      this.initRepasAndComposantesRepasList();
      this.initRegimeOptionList();
      this.startDate = this.selectedStartDateMenus;
      this.minDate = new Date(this.startDate.getTime() + (1000 * 60 * 60 * 24));
      this.endDate = this.setEndDate(this.startDate);
    });

    this.initCommentaire();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subComment);
    this.utils.unsubscribe(this.subPlatsEtComposantesPlats);
    this.utils.unsubscribe(this.subRepasAndComposantesRepas);
  }

  private initCommentaire = () => {
    this.subComment = this.printMenuService.getCommentaire().subscribe(response => {
      this.comment = response.replace(/^"(.*)"$/, '$1');
    });
  }

  setEndDate = (startDate: Date): Date => {
    let endDate: Date;
    const offset: number = this.preferencesUtilisateurService.getPreferenceUtilisateurArrIntValue(USER_PREFERENCE.GESTIONMENUS_DISPLAY_NBJOURS)[0];
    endDate = new Date(startDate);
    endDate.setDate(endDate.getDate() + offset);
    return endDate;
  };

  initRepasAndComposantesRepasList = (): void => {
    this.subRepasAndComposantesRepas = this.menuCompositionService.getRepasAndComposantes(this.contratsMenuConvives[0].id).subscribe(res => {
      const repasWithComposantesRepas: RepasComposanteDTO[] = res.resultList;
      this.buildTreeList(repasWithComposantesRepas);
    });
  }

  downloadBilanAllergene = (): void => {
    const params: BilanAllergeneSearchDto = this.getBilanAllergeneMenuDTO()
    const print$ = this.printMenuService.printBillanAllergene(params);
    print$.subscribe(response => {

      // naming file
      const reportName = `bilan-allergene_du${params.startDate}au${params.endDate}`;

      const blob = new Blob([response], {
        type: MIME_TYPE.PDF // must match the Accept type
      });

      // save file
      fs_saveAs(blob, reportName);

      return of(blob);
    });

    this.displayDialog = false;
  };

  getBilanAllergeneMenuDTO = (): BilanAllergeneSearchDto => {
    const bilanAllergeneMenu: any = {
      startDate: moment(this.startDate).clone().format(DATES_FORMAT.YYYYMMDD),
      endDate: moment(this.endDate).clone().format(DATES_FORMAT.YYYYMMDD),
      site: this.contratsMenuConvives[0].site,
      contratsMenuConviveId: this.contratsMenuConvives[0].id,
      regimeListId: this.selectedRegimes,
      comment: this.comment,
      cmcdIds: this.selectedComposantesRepas,
    };
    return bilanAllergeneMenu;
  };

  initRegimeOptionList = () => {
    _forEach(this.regimeList, (item: ContratMenuConvive__ContrainteAlimDTO) => {
      if (this.utils.isCollectionNullOrEmpty(this.regimeOptionList) || this.regimeOptionList.find(r => r.value != item.regimeId))
        this.regimeOptionList.push({label: item.libelle, value: item.id})
    });
  };

  isValidForm = (): boolean => {
    const isStartDateValid: boolean = !this.utils.isNullOrEmpty(this.startDate);
    const isEndDateValid: boolean = !this.utils.isNullOrEmpty(this.endDate);
    return !(isStartDateValid && isEndDateValid);
  };

  closeDialog = () => {
    this.selectedComposantesRepas = [];
    this.filteredRepasAndComposantesRepas = [];
    this.treeList.expandedRowKeys = [];
    this.displayDialog = false;
  };

  startDateChanged = ($event: any) => {
    this.startDate = $event.value;
    this.minDate = new Date(this.startDate.getTime());
    if (this.minDate > this.endDate) this.endDate = null;
  }

  endDateChanged = ($event: any) => {
    this.endDate = $event.value;
  }

  private buildTreeList(filteredRepasAndComposantesRepas: any[]) {
    this.filteredRepasAndComposantesRepas = filteredRepasAndComposantesRepas.map((repas: RepasComposanteDTO) =>
      ({
        "libelle": repas.libelle,
        "children": repas.composantesRepas,
      })
    );
  }

  updateSelectedRepas = (data): void => {
    const selectedParentsList: number [] = data.component.getSelectedRowsData().filter(r => r.parentId == 0).map(r => r.id);
    selectedParentsList.forEach(rowId => {
      if (!this.selectedRows.includes(rowId)) {
        this.selectedRows = [...this.selectedRows, rowId];
      }
    });
    this.treeList.expandedRowKeys = this.selectedRows;
    const cmcdIds: number[] = [];
    data.component.getSelectedRowsData("leavesOnly").forEach(cr => cmcdIds.push(...cr['cmcd_ids']));
    this.selectedComposantesRepas = cmcdIds;
  };

  onClickRow = (data): void => {
    if (this.selectedRows.includes(data.key)) {
      this.selectedRows = this.selectedRows.filter(key => key !== data.key);
    } else {
      this.selectedRows = [...this.selectedRows, data.key]
    }
    this.treeList.expandedRowKeys = this.selectedRows;
  }
}
